/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from "react";
import { FlightPlanJson } from "../models";
import { fetchFlightsList, FlightsContext, getFlightDetail } from "../store";
import FlightInfo from "./Info";
import FlightSave from "./FlightSave";

export default function FlightsListView() {
  const { state, dispatch } = useContext(FlightsContext);

  const flights = useMemo(() => {
    if (state) return state.flights;
    return [];
  }, [state]);

  useEffect(() => {
    getFlightsList();
    return () => {
      dispatch({
        type: "RESET",
      });
    };
  }, []);

  const getFlightsList = async () => {
    const flightsList = await fetchFlightsList();
    flightsList?.forEach(async (flight) => {
      const detail = await getFlightDetail(flight.flightPlanId);
      if (detail) {
        dispatch({
          type: "ADD",
          payload: detail,
        });
      }
    });
  };

  return (
    <React.Fragment>
      {flights && (
        <div>
          {flights.map((flight: FlightPlanJson) => (
            <div key={flight.flightPlanId} className="my-3 rounded-md border p-4">
              <div className="flex">
                <p className="grow font-bold">{flight.name}</p>
                <FlightSave flight={flight} />
              </div>
              <FlightInfo flightPlanJson={flight} showVideoCapable={false} showAlerts={true} />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}
