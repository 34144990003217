import { makeRequest } from "common/adapters/makeRequest";
// import { Route, RouteProps, optimizeRouteForRequest } from "models/route";
// import type { Location } from "models/location";
// import type { Collection } from "models/collection";
import type { FlightCeilingAlerts } from "models/flight";

const BASE_PATH = "/flights";

// export const fetchCeilingAlertsForCollection = async(coords: string) => {
// if (!coords) throw new Error("coords is required");

//     let ceilingResponse: FlightCeilingAlerts = { locations: [], alerts: [] };

//     const query: Record<string, string> = {};

//     try {
//       const responseBody = await makeRequest({
//         method: "GET",
//         authType: "apiKey",
//         path: `${BASE_PATH}/v2/alerts/restrictions/${coords}`,
//         query,
//       });

//       if (responseBody !== "") {
//         ceilingResponse = responseBody;
//       }
//     } catch (error) {
//       console.error(`Failed to fetch ceiling alerts for coords "${coords}"`);
//     }

//     return ceilingResponse;
// }

class FlightApi {
  static async fetchCeilingAlertsForCollection(coords: string): Promise<FlightCeilingAlerts> {
    if (!coords) throw new Error("coords is required");

    let ceilingResponse: FlightCeilingAlerts = { locations: [], alerts: [] };

    const query: Record<string, string> = {};

    try {
      const responseBody = await makeRequest({
        method: "GET",
        authType: "apiKey",
        path: `${BASE_PATH}/v2/alerts/restrictions/${coords}`,
        query,
      });

      if (responseBody !== "") {
        ceilingResponse = responseBody;
      }
    } catch (error) {
      console.error(`Failed to fetch ceiling alerts for coords "${coords}"`);
    }

    return ceilingResponse;
  }
}

export default FlightApi;
