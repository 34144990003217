import React from "react";

export type MessageDialogProps = {
  title: string;
  message: string;
  show: boolean;
  confirmText?: string;
  closeText?: string;
  confirmStyle?: string;
  cancelStyle?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export default function MessageDialog(props: MessageDialogProps) {
  const {
    title,
    message,
    onCancel,
    onConfirm,
    show,
    confirmText = "Confirm",
    closeText = "Close",
    cancelStyle = "",
    confirmStyle = "",
  } = props;
  return (
    <React.Fragment>
      <input type="checkbox" id="my-modal-4" className="modal-toggle" checked={show} readOnly />
      <div className="modal cursor-pointer">
        <div className="modal-box relative">
          {onCancel && (
            <button className="btn btn-circle btn-sm absolute right-2 top-2" onClick={onCancel}>
              ✕
            </button>
          )}
          <h3 className="text-2xl font-bold">{title}</h3>
          <p className="py-4 text-lg">{message}</p>
          <div className="modal-action justify-end">
            {onConfirm && (
              <button className={`btn ${confirmStyle}`} onClick={onConfirm}>
                {confirmText}
              </button>
            )}
            {onCancel && (
              <button className={`btn ${cancelStyle}`} onClick={onCancel}>
                {closeText}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
