import React from "react";
import Flights from "./Flights";

export default function FlightsPage() {
  return (
    <div className="h-full min-h-[100vh] bg-white">
      <div className="mx-auto max-w-[1000px]">
        <Flights activeMenu={null} listView={true} />
      </div>
    </div>
  );
}
