import React from "react";
import { weatherAlertProducts, WeatherAlertType } from "map/services/weatherService";
import { WeatherAlertWithoutPolygon } from "models/weatherAlert";
import { Icon, productIcons, S4dIcon } from "common/icon-library";

/**
 * Helper to lookup the icon and background color to use for each weather alert type
 *
 * @param product
 * @returns
 */
function getWeatherAlertIcon(product: WeatherAlertType) {
  const wap = weatherAlertProducts[product];
  const icon = productIcons[product].solidIcon as Icon;
  const bgColor = wap.color;
  return { bgColor, icon };
}

/**
 * Gets detail text from the weather alert feature
 *
 * NOTE: For some reason the detail text seems to be a stringified array. This has to be a bug.
 *
 * @param {WeatherAlertWithoutPolygon} alertProps
 * @return {DetailText} DetailText
 */
function getDetailText(alertProps: WeatherAlertWithoutPolygon) {
  const languageCode = window.navigator.language ?? "en-US";
  let { detailTexts } = alertProps;
  if (!Array.isArray(detailTexts)) detailTexts = JSON.parse((alertProps.detailTexts as any) ?? "");
  let text = (detailTexts || []).find((t) => t.languageCode === languageCode);
  if (!text && detailTexts?.length) {
    text = detailTexts[0];
  }
  return text;
}

/**
 * Weather Alert Popup component
 *
 * @param props
 * @returns
 */
export default function WeatherAlertPopup(props: {
  product: WeatherAlertType;
  timeIndex: number;
  alertProps: WeatherAlertWithoutPolygon;
  id: string;
}) {
  const { product, alertProps } = props;
  const { bgColor, icon } = getWeatherAlertIcon(product);
  const dt = new Date(alertProps.issueTimeLocal);
  const text = getDetailText(alertProps);

  return (
    <div className="popup-c flex min-w-[220px] max-w-[500px]" key={props.id}>
      <div
        className="tab-icon w-[50px] grow cursor-pointer rounded-tl-md rounded-bl-md p-2"
        style={{ backgroundColor: bgColor }}>
        {<S4dIcon icon={icon} />}
      </div>
      <div className="grow py-2 px-4">
        <div className="text-lg font-bold leading-normal">Weather Alert</div>
        <div className="text-base leading-normal">{alertProps.eventDescription}</div>
        <hr />
        <div className="text-base font-medium">{alertProps.areaName}</div>
        <div className="max-h-[250px] overflow-y-scroll py-1">
          <pre className="whitespace-pre-wrap text-xs">
            {removeSingleNewlines(text?.description ?? "No details provided")}
          </pre>
        </div>
        <hr />
        <div>
          <div>{alertProps.attributionString}</div>
          <div>{`Issued on ${dt.toLocaleDateString()} at ${dt.toLocaleTimeString()}`}</div>
        </div>
      </div>
    </div>
  );
}

function removeSingleNewlines(str: string): string {
  return str.replace(/([^\r\n])\n([^\r\n])/g, "$1 $2").replace(/&&[\s\n]*$/g, "");
}
