import type { Feature, Polygon } from "@turf/turf";
import { FlightPlanJson } from "flight-plan/models";
import { MapService } from "map/services/mapService";
import { MapboxMarker } from "models";
import logo from "assets/icons/drone2.svg";
import { getSurveyBoundary } from "flight-plan/helpers/flight-path";
import { getCentroid } from "flight-plan/helpers";
import { FlightService } from "./flightServices";

export type FlightPinProps = {
  id: string;
  mapService: MapService;
  flightPlan: FlightPlanJson;
  flightService: FlightService;
  visible?: boolean;
};

export class FlightPin {
  mapService: MapService;
  flightPlan: FlightPlanJson;
  flightService: FlightService;
  isVisible = true;
  marker?: MapboxMarker;

  constructor(props: FlightPinProps) {
    const { visible = true } = props;
    this.mapService = props.mapService;
    this.flightPlan = props.flightPlan;
    this.flightService = props.flightService;
    this.isVisible = visible;
    this.buildPin();
  }

  buildPin() {
    if (this.marker) this.marker.remove();
    const div = document.createElement("div");
    div.classList.add(
      "flex",
      "bg-white",
      "rounded-full",
      "px-2",
      "py-1",
      "items-center",
      "min-w-[70px]",
      "cursor-pointer",
    );
    const img = document.createElement("img");
    img.classList.add("w-[40px]", "mr-1");
    const p = document.createElement("p");
    img.src = logo;
    img.height = 30;
    p.innerText = this.flightPlan.name;
    div.appendChild(img);
    div.appendChild(p);
    div.addEventListener("click", this.onClick);
    this.marker = this.mapService.addMarker(this.getCenter(), "bottom", div);
  }

  onClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    this.flyTo();
    if (this.flightService.active?.flightPlanId !== this.flightPlan.flightPlanId) {
      this.flightService.dispatch({
        type: "ACTIVE",
        payload: this.flightPlan,
      });
    }
  };

  getCenter() {
    const center = getCentroid(this.boundary as Feature<Polygon>);
    const coordinates = center.geometry.coordinates;
    return { lng: coordinates[0], lat: coordinates[1] };
  }

  flyTo() {
    this.mapService.fitTo(this.boundary);
  }

  get boundary() {
    return getSurveyBoundary(this.flightPlan);
  }

  updateFlight(flightPlan?: FlightPlanJson) {
    if (flightPlan) this.flightPlan = flightPlan;
    this.marker?.setLngLat(this.getCenter());
  }

  setVisible(visible: boolean) {
    this.isVisible = visible;
    const el = this.marker?.getElement();
    if (el && visible) el.classList.remove("hidden");
    if (el && !visible) el.classList.add("hidden");
  }

  remove() {
    if (this.marker) this.marker.remove();
  }

  getId() {
    return this.flightPlan.flightPlanId;
  }
}
