import React, { useMemo } from "react";
import FlightSettingsForm from "./Form";
import { useContext } from "react";
import { FlightsContext } from "../store";
import { FlightMetadata } from "../models";

export default function FlightSettings() {
  const { state, dispatch } = useContext(FlightsContext);

  const editFlight = useMemo(() => {
    if (state && state.editFlight) {
      return state.editFlight;
    }
  }, [state]);

  const flightMetadata = useMemo(() => {
    if (editFlight) {
      return editFlight.flightMetadata;
    }
    return null;
  }, [editFlight]);

  const updateMetadata = (flightMetadata: FlightMetadata) => {
    const update = { ...editFlight, flightMetadata: flightMetadata };
    dispatch({
      type: "EDIT",
      payload: update,
    });
  };

  return (
    <React.Fragment>
      {state && state.editFlight && flightMetadata && (
        <FlightSettingsForm
          key={state.editFlight?.flightPlanId}
          flightMetadata={flightMetadata}
          updateMetadata={updateMetadata}
        />
      )}
    </React.Fragment>
  );
}
