import React, { useMemo } from "react";
import amplifyConfig from "amplifyConfig";
import { VectorTileLayer } from "map/services/vectorTileLayer";
import { FillExtrusionLayer, FillLayer, LineLayer, SymbolLayer } from "models";
import { MapOverlay } from "../components/MapOverlay";
import { LightenDarkenColor as lighten } from "common/utils/colors";
import { useMap } from "map/services/useMap";
import { BaseMapLayerProps } from "map/services/baseMapLayer";
import FlightRestrictionPopup, { RestrictionPropType } from "map/popups/FlightRestrictionPopup";

const API_BASE_URL = `https://${amplifyConfig?.ApiConfig?.host ?? "api.signal4d.com"}`;
const faaTiles = [`${API_BASE_URL}/flights/v2/tile/{z}/{x}/{y}.pbf`];

const sourceLayer = "flightRestrictions";

export const ifPropertyEquals = (
  property: string,
  value: string | number | boolean,
  color1: string,
  color2: string,
): mapboxgl.Expression => ["case", ["==", ["get", property], value], color1, color2];

const restrictionTypes = [
  { id: "FAA_FacilitiesFT", type: "FullTime" },
  { id: "FAA_FacilitiesPT", type: "PartTime" },
  { id: "Prohibited", type: "Prohibited" },
] as const;
type RestrictionType = typeof restrictionTypes[number]["id"];

class FlightRestrictionsMapLayer extends VectorTileLayer {
  opacity = 0.7;
  lineColor = "#ab82e0";
  in3D = true;
  restrictionType: typeof restrictionTypes[number];

  constructor(props: { id: RestrictionType } & BaseMapLayerProps) {
    super(props);
    const restrictionType = restrictionTypes.find((t) => t.id === props.id);
    this.restrictionType = restrictionType ?? restrictionTypes[0];
    this.in3D = true;
  }

  set3dMode(mode: boolean) {
    this.in3D = mode;
    this.layers = this.buildLayers();
    this.layers.forEach((layer) => {
      if (layer.paint)
        Object.entries(layer.paint).forEach(([key, val]) => this.map?.setPaintProperty(layer.id, key, val));
      if (layer.layout)
        Object.entries(layer.layout).forEach(([key, val]) => this.map?.setLayoutProperty(layer.id, key, val));
    });
  }
  getSourceProps(): mapboxgl.VectorSource {
    return {
      type: "vector",
      tiles: faaTiles,
      minzoom: 4,
      maxzoom: 11,
    };
  }

  buildLayers() {
    const maxHeightInFeet = 420;
    const feetToMeters = 0.3048;
    const heightMultiplier = this.in3D ? 1 : 0;
    const opacity = this.opacity;
    const layers: (FillExtrusionLayer | FillLayer | LineLayer | SymbolLayer)[] = [];

    let lineColor: mapboxgl.LinePaint["line-color"];
    let fillColor: mapboxgl.FillPaint["fill-color"];
    let textColor: mapboxgl.SymbolPaint["text-color"];
    const id = this.restrictionType.id;

    switch (this.restrictionType.type) {
      case "FullTime":
      case "PartTime":
        fillColor = ifPropertyEquals("APT1_LAANC", 1, "#74A466", "#F35A52");
        lineColor = ifPropertyEquals("APT1_LAANC", 1, lighten("#74A466", 10), lighten("#F35A52", 10));
        textColor = ifPropertyEquals("APT1_LAANC", 1, "#a60a02", "#5c0400");
        break;
      case "Prohibited":
      default:
        lineColor = "#c45626";
        fillColor = "#d35c29";
        textColor = "black";
        break;
    }

    const base = {
      minzoom: 4,
      maxzoom: 22,
      source: this.id,
      "source-layer": "flightRestrictions",
      filter: ["==", ["get", "type"], this.restrictionType.type],
      layout: {
        visibility: this.isVisible ? "visible" : "none",
      },
    };

    const fillLayer = {
      ...base,
      id: `${id}-fill`,
      type: "fill",
      paint: {
        "fill-color": fillColor,
        "fill-opacity": this.in3D ? 0 : opacity,
      },
    } as FillLayer;

    const fill3dLayer = {
      ...base,
      id: `${id}-fill3d`,
      type: "fill-extrusion",
      paint: {
        "fill-extrusion-color": fillColor,
        "fill-extrusion-height": maxHeightInFeet * feetToMeters * heightMultiplier,
        "fill-extrusion-base": ["*", ["to-number", ["get", "CEILING"], 0], feetToMeters * heightMultiplier],
        "fill-extrusion-opacity": this.in3D ? opacity : 0,
        "fill-extrusion-vertical-gradient": true,
      },
    } as FillExtrusionLayer;

    const lineLayer = {
      ...base,
      id: `${id}-line`,
      type: "line",
      layout: {
        "line-cap": "round",
        "line-join": "round",
        visibility: this.isVisible && !this.in3D ? "visible" : "none",
      },
      paint: {
        "line-opacity": ["case", ["==", ["get", "CEILING"], 0], 1, 0.8],
        "line-color": lineColor,
        "line-width": ["interpolate", ["linear"], ["zoom"], 0, 2, 10, 2, 16, 2],
      },
    } as LineLayer;

    const textLayer = {
      ...base,
      minzoom: 11,
      id: `${id}-labels`,
      type: "symbol",
      paint: {
        "text-opacity": ["interpolate", ["linear"], ["zoom"], 0, 0, 8, 0, 12, 1],
        "text-color": textColor,
      },
      layout: {
        ...base.layout,
        "text-field": ["get", "CEILING"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 0],
        "text-size": ["interpolate", ["linear"], ["zoom"], 0, 12, 11, 12, 22, 36],
        "text-anchor": "center",
        "text-allow-overlap": true,
      },
    } as SymbolLayer;

    layers.push(...[fillLayer, fill3dLayer, lineLayer, textLayer]);

    return layers;
  }

  setVisible(visible: boolean, timeIndex?: number): void {
    super.setVisible(visible, this.timeIndex);
    if (!this.layers.length) return;
    const id = this.layers[0].id;
    if (this.isVisible) {
      this.on("click", id, this.onClick);
    } else {
      this.off("click", id, this.onClick);
    }
  }

  onClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const feature = e.features?.find((f) => f.sourceLayer === sourceLayer) as GeoJSON.Feature;
    if (!feature) return;

    const restrictionProps = feature.properties as RestrictionPropType;
    if (!restrictionProps || Object.keys(restrictionProps).length === 0) return;

    const el = <FlightRestrictionPopup key={this.id} id={this.id} restrictionProps={restrictionProps} />;
    this.popup?.add({
      coordinates: [e.lngLat.lng, e.lngLat.lat],
      content: el,
    });
  };
}

export function FlightRestrictionLayer() {
  const mapService = useMap();
  const name = "Flight Restrictions";

  const flightRestrictionsMapLayers = useMemo(
    () => (mapService ? restrictionTypes.map((t) => new FlightRestrictionsMapLayer({ id: t.id, mapService })) : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay
        layers={flightRestrictionsMapLayers}
        visibility={"2d"}
        visibilityModes={["none", "2d", "3d"]}
        name={name}
        opacity={0.7}
      />
    </React.Fragment>
  );
}

export default FlightRestrictionLayer;
