import { getSurveyBoundary } from "flight-plan/helpers/flight-path";
import { FlightPlanJson } from "flight-plan/models";
import { GeojsonLayer, GeojsonLayerProps } from "map/services/geojsonLayer";
import { FillLayer, LineLayer } from "mapbox-gl";

export type FlightBoundaryProps = GeojsonLayerProps & {
  flightPlan: FlightPlanJson;
};

export class FlightBoundary extends GeojsonLayer {
  flightPlan: FlightPlanJson;

  constructor(props: FlightBoundaryProps) {
    super(props);
    this.flightPlan = props.flightPlan;
    this.data = {
      features: [this.boundary],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }
  protected async updateData() {}

  get boundary() {
    return getSurveyBoundary(this.flightPlan);
  }

  buildLayers() {
    return [
      {
        minzoom: 4,
        maxzoom: 22,
        source: `${this.id}`,
        id: `${this.id}-fill`,
        type: "fill",
        paint: {
          "fill-opacity": 0.3,
          "fill-color": "#ec9f47",
        },
      } as FillLayer,
      {
        minzoom: 4,
        maxzoom: 22,
        type: "line",
        id: `${this.id}-line`,
        source: this.id,
        paint: {
          "line-opacity": 0.8,
          "line-color": "gray",
          "line-width": ["interpolate", ["linear"], ["zoom"], 0, 2, 10, 2, 16, 2],
        },
      } as LineLayer,
    ];
  }

  updateFlight(flightPlan: FlightPlanJson) {
    if (flightPlan) this.flightPlan = flightPlan;
    this.data = {
      features: [this.boundary],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }

  remove() {
    this.layers.forEach((layer) => {
      this.removeLayer(layer.id);
    });
  }

  getId() {
    return this.flightPlan.flightPlanId;
  }
}
