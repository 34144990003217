import React, { useEffect, useRef, useState } from "react";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { MapService } from "./services/mapService";
import MapSidebar from "./components/MapSidebar";
import { getLocalStorageItem } from "common/adapters/localStorage";

const defaultMapViewState = getLocalStorageItem("mapState") || {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 10,
  bearing: 0,
  pitch: 0,
};

export const MapContext = React.createContext<MapService | undefined>(undefined);

export default function Map() {
  const mapContainer = useRef(null);
  const [mapInstance, setMapInstance] = useState<MapService | undefined>();
  const mapRef = useRef<MapService | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (mapRef.current) return;

    const map = new MapService({ mapViewState: defaultMapViewState });
    setMapInstance(map);
    map.onLoaded = () => setLoaded(true);
    map.initMap(mapContainer.current);
    mapRef.current = map;

    return () => {
      setLoaded(false);
      if (mapRef && mapRef.current) {
        setMapInstance(undefined);
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  return (
    <MapContext.Provider value={mapInstance}>
      <div ref={mapContainer} className="map-container h-[100vh]" />
      {loaded && <MapSidebar />}
    </MapContext.Provider>
  );
}
