import React, { useMemo } from "react";
import { MapOverlay } from "../components/MapOverlay";
import type { FillExtrusionLayer } from "mapbox-gl";
import { VectorTileLayer } from "map/services/vectorTileLayer";
import { useMap } from "map/services/useMap";
import mapboxgl from "mapbox-gl";
import BuildingPopup from "map/popups/BuildingPopup";

const name = "Buildings";

class BuildingsMapLayer extends VectorTileLayer {
  fillColor = "#ddd";

  getSourceProps(): mapboxgl.VectorSource {
    return {
      type: "vector",
      url: "mapbox://mapbox.mapbox-streets-v8",
    };
  }

  buildLayers() {
    const opacity = this.opacity;
    return [
      {
        id: this.id + "-fill",
        type: "fill-extrusion",
        source: this.id,
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        paint: {
          "fill-extrusion-color": this.fillColor,
          "fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "height"]],
          "fill-extrusion-base": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "min_height"]],
          "fill-extrusion-opacity": opacity,
        },
        layout: {
          visibility: this.isVisible ? "visible" : "none",
        },
      } as FillExtrusionLayer,
    ];
  }

  setVisible(visible: boolean, timeIndex?: number): void {
    super.setVisible(visible, this.timeIndex);
    const id = this.layers[0].id;
    if (this.isVisible) {
      this.on("click", id, this.onClick);
    } else {
      this.off("click", id, this.onClick);
    }
  }

  onClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const building = e.features?.find((item) => item.layer.source === this.id);
    if (building && building.properties?.height) {
      const el = <BuildingPopup key={this.id} height={building.properties.height} />;
      this.popup?.add({
        coordinates: [e.lngLat.lng, e.lngLat.lat],
        content: el,
      });
    }
  };
}

export default function Buildings() {
  const mapService = useMap();

  const layers = useMemo(
    () => (mapService ? [new BuildingsMapLayer({ id: "buildings", mapService, opacity: 0.7 })] : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay layers={layers} opacity={0.7} visibility={"3d"} visibilityModes={["none", "3d"]} name={name} />
    </React.Fragment>
  );
}
