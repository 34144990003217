import { Auth } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { appLinkToken } from "./Login";

export type TokenParams = {
  token: string;
};

export default function Token() {
  const params = useParams<TokenParams>();
  const navigate = useNavigate();
  const signOut = async () => {
    try {
      await Auth.signOut();
      navigate("/authenticate");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  if (params.token) {
    // if we have a token do a window.location.href to redirect to the app deep-link URL. This way if the app is not
    // registered in the system it will do nothing.
    const redirectUrl = appLinkToken.replace("{token}", params.token);
    window.location.href = redirectUrl;
  }

  return (
    <div className="h-[100vh] bg-white">
      <div className="mx-auto max-w-[900px]">
        <p className="break-all" id="token">
          {params.token}
        </p>
        <br />
        <button className="btn" onClick={() => signOut()}>
          Sign Out
        </button>
      </div>
    </div>
  );
}
