import SearchIcon from "assets/nav-icons/search.svg";
import LayerIcon from "assets/nav-icons/layers.svg";
import DroneIcon from "assets/nav-icons/drone.svg";
import CreateIcon from "assets/nav-icons/plus.svg";
import UserIcon from "assets/nav-icons/user.svg";
import Search from "map/components/search/Search";
import { mapService } from "map/services/mapService";
import { Place } from "map/components/search/searchService";
import { MouseEventHandler, useState } from "react";
import Layers from "map/layers/Layers";
import Flights from "../../flight-plan/Flights";
import UserCard from "authenticator/UserCard";

export type MenuType = "search" | "layers" | "flights" | "create" | "account" | null;

function MapSidebarButton(props: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  id: MenuType;
  icon: string;
  tooltip: string;
}) {
  const { onClick, id, icon, tooltip } = props;
  return (
    <div className="cursor-pointer border-b border-slate-300 last:border-b-0">
      <button className="flex w-full justify-center p-2" onClick={onClick} data-id={id}>
        <img className="h-[18px] w-auto" src={icon} alt={tooltip} />
      </button>
    </div>
  );
}

export default function MapSidebar(props: any) {
  const menuState = useState<MenuType>(null);
  const [activeMenu, setActiveMenu] = menuState;
  const onClick = (ev: any) =>
    activeMenu === ev.currentTarget.dataset.id ? setActiveMenu(null) : setActiveMenu(ev.currentTarget.dataset.id);
  const onSearchLocation = (place: Place) => {
    mapService.flyToLocation(place.center, place.place_type?.[0] === "address" ? 19 : 15);
  };

  return (
    <div className="z-10">
      <div className="fixed left-0 top-0 ml-2 mt-2">
        <div className="align-center flex w-[50px] flex-col justify-center rounded-xl bg-white">
          <MapSidebarButton onClick={onClick} id="search" icon={SearchIcon} tooltip="Search" />

          <MapSidebarButton onClick={onClick} id="layers" icon={LayerIcon} tooltip="Map Layers" />

          <MapSidebarButton onClick={onClick} id="flights" icon={DroneIcon} tooltip="Flights" />

          <MapSidebarButton onClick={onClick} id="create" icon={CreateIcon} tooltip="Create flight" />

          <MapSidebarButton onClick={onClick} id="account" icon={UserIcon} tooltip="User Account" />
        </div>
      </div>

      <div className="absolute left-[60px] top-0 z-10 ml-2 mt-2 max-h-[calc(100vh_-_0.5rem)] overflow-y-scroll">
        <div className={`${activeMenu !== "search" ? "hidden" : ""}`}>
          <Search successCallback={onSearchLocation} />
        </div>
        <div className={`${activeMenu !== "layers" ? "hidden" : ""}`}>
          <Layers />
        </div>
        <Flights activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <div className={`${activeMenu !== "account" ? "hidden" : ""}`}>
          <UserCard />
        </div>
      </div>
    </div>
  );
}
