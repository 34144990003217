import mapboxgl, { MapLayerEventType, EventData } from "mapbox-gl";
import { Dictionary } from "common/utils/types";
import { DrawEventType } from "@mapbox/mapbox-gl-draw";

export class LayerEventHandler {
  map: mapboxgl.Map;
  handlers: Dictionary<keyof MapLayerEventType | DrawEventType, any>;
  defaultHandlers: Dictionary<keyof MapLayerEventType | DrawEventType, any>;

  constructor(map: mapboxgl.Map) {
    this.map = map;

    this.handlers = {};
    this.defaultHandlers = {};
    this._onMapEvent = this._onMapEvent.bind(this);
  }

  getHandleLayers(eventName: keyof MapLayerEventType) {
    console.log(eventName);
    if (!this.handlers[eventName]) {
      return [];
    }
    return Object.keys(this.handlers[eventName]);
  }

  on<T extends keyof MapLayerEventType>(
    eventName: T | DrawEventType,
    layerId: string | null,
    callback: (ev: MapLayerEventType[T] & EventData) => void,
    preventDefault?: boolean,
  ) {
    if (!this.defaultHandlers[eventName] && !this.handlers[eventName]) {
      // Create new event name keys in our storage maps
      this.defaultHandlers[eventName] = [];
      this.handlers[eventName] = {};

      // Register a map event for the given event name
      this.map.on(eventName, this._onMapEvent);
      // this.map.on(eventName, layerId, callback);
    }

    if (!layerId) {
      // layerId is not specified, so this is a 'default handler' that will be called if no other events have cancelled the event
      this.defaultHandlers[eventName].push({ callback, preventDefault });
    } else {
      // layerId is specified, so this is a specific handler for that layer
      this.handlers[eventName][layerId] = callback;
    }
  }
  // off<T extends keyof MapLayerEventType>(eventName?: T, layerId?: string, listener?: (ev: MapLayerEventType[T] & EventData) => void) {
  off<T extends keyof MapLayerEventType | DrawEventType>(eventName: T, layerId?: string) {
    if (layerId && this.handlers[eventName] && this.handlers[eventName][layerId]) {
      this.handlers[eventName][layerId] = null;
      delete this.handlers[eventName][layerId];
    }

    if (!layerId && this.defaultHandlers[eventName]) {
      this.map.off(eventName, this.defaultHandlers[eventName].callback);
      this.handlers[eventName] = null;
      this.defaultHandlers[eventName] = null;
      delete this.handlers[eventName];
      delete this.defaultHandlers[eventName];
    }
  }

  _onMapEvent(event: any) {
    let bubbleEvent = true;
    let key: keyof MapLayerEventType | DrawEventType;
    for (key in this.defaultHandlers) {
      if (!bubbleEvent) break;
      bubbleEvent = !this.defaultHandlers[key].find((handler: any) => handler.preventDefault);
      if (event.type !== key) continue;
      this.defaultHandlers[key].forEach((handler: any) => {
        if (handler.callback) handler.callback(event);
      });
    }

    if (!bubbleEvent) return;

    const layers = this.getHandleLayers(event.type); //unordered list of layers to be checked

    const eventName = event.type as keyof MapLayerEventType;

    // This gets the features that was clicked in the correct layer order
    const eventFeatures = this.map.queryRenderedFeatures(event.point, { layers: layers });
    // console.log(eventFeatures);

    // This makes a sorted array of the layers that are clicked
    const sortedLayers = eventFeatures.reduce((sorted, next) => {
      const nextLayerId = next.layer.id;
      if (sorted.indexOf(nextLayerId) === -1) {
        return sorted.concat([nextLayerId]);
      }
      return sorted;
    }, [] as string[]);

    // Add the layers and features info to the event
    event.layers = sortedLayers;
    event.features = eventFeatures;

    // Loop through each of the sorted layers starting with the first (top-most clicked layer)
    // Call the handler for each layer in order, and potentially stop propagating the event
    for (let i = 0; i < sortedLayers.length; i++) {
      const layerId = sortedLayers[i];
      this.handlers[eventName][layerId](event);
    }
  }
}
