import type { Feature, Polygon } from "@turf/turf";
import length from "@turf/length";
import { generateBuffer } from "flight-plan/helpers";
import { getSurveyPathFeature } from "flight-plan/helpers/flight-path";
import { FlightPlanJson } from "flight-plan/models";
import { GeojsonLayer, GeojsonLayerProps } from "map/services/geojsonLayer";
import { FillExtrusionLayer } from "mapbox-gl";

export type FlightPathProps = GeojsonLayerProps & {
  flightPlan: FlightPlanJson;
  is3d: boolean;
};

export class ExtrudedPath extends GeojsonLayer {
  flightPlan: FlightPlanJson;
  is3d: boolean;

  constructor(props: FlightPathProps) {
    const { visible = true, flightPlan, is3d } = props;
    super(props);
    this.flightPlan = flightPlan;
    this.is3d = is3d;
    this.isVisible = this.is3d && visible;
    this.data = {
      features: [this.path],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }

  protected async updateData() {}

  buildLayers() {
    if (!this.flightPlan) return [];
    const altitude = this.flightPlan.flightMetadata.defaultAltitude || 50;
    return [
      {
        id: `${this.id}-path-extrusion`,
        source: `${this.id}`,
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": "#445ffe",
          "fill-extrusion-height": altitude + 0.5,
          "fill-extrusion-base": altitude,
          "fill-extrusion-opacity": 1,
        },
        layout: {
          visibility: this.is3d && this.isVisible ? "visible" : "none",
        },
      } as FillExtrusionLayer,
    ];
  }

  get path() {
    // temporary fix. need a better solution
    const path = getSurveyPathFeature(this.flightPlan);
    const pathLength = length(path, { units: "kilometers" });
    if (pathLength > 10000) {
      return { type: "Polygon" };
    } else {
      return generateBuffer(path) as Feature<Polygon>;
    }
  }

  updateFlight(flightPlan: FlightPlanJson) {
    if (flightPlan) this.flightPlan = flightPlan;
    this.data = {
      features: [this.path],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }

  set3d(is3d: boolean) {
    this.is3d = is3d;
    this.setVisible(true);
  }

  setVisible(visible: boolean) {
    this.isVisible = visible && this.is3d;
    this.update();
  }

  remove() {
    this.layers.forEach((layer) => {
      this.removeLayer(layer.id);
    });
  }

  getId() {
    return this.flightPlan.flightPlanId;
  }
}
