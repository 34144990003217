/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appLinkToken, appLinkLoginNeeded } from "./Login";

const isDebug = process.env.NODE_ENV === "development";

export default function RefreshToken() {
  const navigate = useNavigate();

  const refreshToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const refreshedToken = currentSession.getIdToken().getJwtToken();

      const redirectUrl = appLinkToken.replace("{token}", refreshedToken);
      window.location.href = redirectUrl;
      isDebug && navigate(`/token/${refreshedToken}`);
    } catch (err) {
      window.location.href = appLinkLoginNeeded;
      navigate(`/authenticate`);
    }
  };

  useEffect(() => {
    refreshToken();
  }, []);
  return <div>refresh token</div>;
}
