import React from "react";
import useCeilingAlerts from "flight-plan/hooks/useCeilingAlert";
import { FlightPlanJson } from "flight-plan/models";
import AlertIcon from "assets/icons/alert-triangle.svg";
import convert from "common/utils/converter";
import { CeilingAlerts } from "models/flight";

export default function AlertSummary({ flight }: { flight: FlightPlanJson }) {
  const alerts = useCeilingAlerts(flight);
  const length = alerts?.alerts?.length;
  if (length) {
    return (
      <div className="ml-1 flex items-center">
        <img src={AlertIcon} alt="" className="mr-1 h-[16px]" />
        <p className="text-rose-600">
          {length} Flight Restriction{length > 1 ? "s" : ""}
        </p>
      </div>
    );
  }
  return null;
}

export function AlertDetails({ flight, className }: { flight: FlightPlanJson; className?: string }) {
  const alerts = useCeilingAlerts(flight);
  return (
    <div className={alerts?.alerts?.length ? "" : "flex"}>
      <p className="font-semibold">Flight Restrictions:</p>
      {alerts?.alerts?.length ? (
        alerts.alerts.map((alert, index) => (
          <div key={index} className="flex items-center pl-2">
            <img src={AlertIcon} alt="" className="mr-1 h-[16px]" />
            <p className="text-rose-600">{`${alert.name} - ${inFeet(alert)}'`}</p>
          </div>
        ))
      ) : (
        <p className="ml-auto">None</p>
      )}
    </div>
  );

  return null;
}

function inFeet(alert: CeilingAlerts): React.ReactNode {
  return Math.round(
    convert(+alert.ceilingMeters)
      .from("m")
      .to("ft"),
  );
}
