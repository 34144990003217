import React, { useMemo } from "react";
import { RasterDemSource, SkyLayer } from "mapbox-gl";
import { BaseMapLayer } from "map/services/baseMapLayer";
import { useMap } from "map/services/useMap";
import { MapOverlay } from "map/components/MapOverlay";

class TerrainMapLayer extends BaseMapLayer {
  terrainSettings = { source: "mapbox-dem", exaggeration: 1.5 };

  async update() {
    try {
      if (this.layers.length === 0) this.layers = this.buildLayers();
      this.layers.forEach((layerProps) => {
        const layer = this.map?.getLayer(layerProps.id);
        if (!layer) {
          this.map?.addLayer({
            ...layerProps,
            layout: { ...layerProps.layout },
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  protected updateSource(): void {
    if (this.map?.getSource(this.id)) return;
    this.map?.addSource(this.id, this.getSourceProps());
  }
  getSourceProps() {
    return {
      type: "raster-dem",
      url: "mapbox://mapbox.mapbox-terrain-dem-v1",
      tileSize: 512,
      maxzoom: 14,
    } as RasterDemSource;
  }

  buildLayers() {
    return [
      {
        id: "sky",
        type: "sky",
        paint: {
          "sky-type": "atmosphere",
          "sky-atmosphere-sun": [0.0, 0.0],
          "sky-atmosphere-sun-intensity": 15,
        },
      } as SkyLayer,
    ];
  }

  setVisible(visible: boolean, timeIndex?: number): void {
    try {
      if (!this.map) return;
      if (!this.map?.getSource(this.id)) this.updateSource();
      this.isVisible = visible;
      if (this.isVisible) {
        this.map?.setTerrain(this.terrainSettings);
      } else {
        this.map?.setTerrain();
      }
      this.update();
    } catch (e) {
      console.log(e);
    }
  }

  set3dMode(mode: boolean): void {
    this.setVisible(mode);
  }
}

export default function Terrain() {
  const mapService = useMap();

  const layers = useMemo(
    () => (mapService ? [new TerrainMapLayer({ id: "mapbox-dem", mapService })] : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay layers={layers} visibility={"3d"} visibilityModes={["2d", "3d"]} name={"Terrain"} />
    </React.Fragment>
  );
}
