/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { AppContext } from "App";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "./Authenticator";

export function Logout() {
  const { signOut } = useAuthenticator((context) => [context.route]);
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    const logout = async () => {
      try {
        // Clear the cache so no user data is shown when logging in as another account
        // Note: We use `clear` instead of `invalidateQueries` because some of the map layers
        //       rely on the cache even when no hook-based cache connections are active,
        //       so we need to actually clear the data, not just mark the queries as stale.
        // QueryCache.clear();
        setUser?.(undefined);
        signOut();
      } catch (err) {
        console.error(err);
      }
    };

    logout();
  }, []);

  return <Navigate to="/" />;
}

export default Logout;
