// cspell:word powerlines
import amplifyConfig from "amplifyConfig";
import React, { useMemo } from "react";
import type { SymbolLayer } from "mapbox-gl";
import SubstationIcon from "assets/icons/substation.svg";
import { MapOverlay } from "../components/MapOverlay";
import { useMap } from "map/services/useMap";
import { VectorTileLayer } from "map/services/vectorTileLayer";
import { BaseMapLayerProps } from "map/services/baseMapLayer";

const API_BASE_URL = `https://${amplifyConfig?.ApiConfig?.host ?? "api.signal4d.com"}`;
const substationTile = [`${API_BASE_URL}/powerlines/v2/tile/substations/{z}/{x}/{y}.pbf`];

class SubstationsMapLayer extends VectorTileLayer {
  opacity = 1;
  lineColor = "#ab82e0";

  constructor(props: BaseMapLayerProps) {
    super(props);
    this.addImageToMap("substationIcon", SubstationIcon, { width: 28, height: 28 });
  }
  getSourceProps(): mapboxgl.VectorSource {
    return {
      type: "vector",
      tiles: substationTile,
      minzoom: 4,
      maxzoom: 11,
    };
  }

  buildLayers() {
    return [
      {
        id: `${this.id}-icon`,
        type: "symbol",
        source: this.id,
        "source-layer": "substations",
        layout: {
          "icon-image": "substationIcon",
          visibility: this.isVisible ? "visible" : "none",
          "text-allow-overlap": true,
          "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 0.3, 15, 1.2, 22, 5],
          "icon-allow-overlap": true,
          "icon-keep-upright": true,
        },
        paint: {
          "icon-opacity": 1,
          "icon-color": "#000000",
          "icon-halo-color": "#333333",
        },
      } as SymbolLayer,
    ];
  }
}

export default function SubstationLayer() {
  const id = "substations";
  const name = "Substations";
  const mapService = useMap();

  const substationsMapLayers = useMemo(
    () => (mapService ? [new SubstationsMapLayer({ id, mapService })] : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay
        layers={substationsMapLayers}
        visibility={"visible"}
        visibilityModes={["none", "visible"]}
        name={name}
      />
    </React.Fragment>
  );
}
