import mapboxgl, { MapLayerEventType, EventData } from "mapbox-gl";
import { MapService } from "./mapService";

export type AnyLayerExceptCustom = Exclude<mapboxgl.AnyLayer, mapboxgl.CustomLayerInterface>;

export type BaseMapLayerProps = {
  id: string;
  mapService: MapService;
  visible?: boolean;
  opacity?: number;
  timeIndex?: number;
  updateInterval?: number;
};

export abstract class BaseMapLayer {
  id: string;
  isVisible: boolean;
  timeIndex: number;
  events: [keyof MapLayerEventType, string, Function][] = [];
  mapService: MapService;
  opacity?: number;
  protected map: mapboxgl.Map | null;
  protected layers: AnyLayerExceptCustom[] = [];
  protected updateInterval?: number;
  protected updateTimer: NodeJS.Timeout | null = null;
  data?: GeoJSON.FeatureCollection;

  constructor(props: BaseMapLayerProps) {
    const { id, visible = false, mapService, timeIndex = 0, updateInterval } = props;
    this.id = id;
    this.mapService = mapService;
    this.map = mapService.getMap();
    this.isVisible = visible;
    this.timeIndex = timeIndex;
    this.updateInterval = updateInterval;
    this.opacity = props.opacity ?? 1;
  }

  abstract update(): Promise<void>;

  /**
   * Create or update the source for this layer.
   * Subclasses must implement this themselves, a minimal implementation could look like:
   *
   * ```
   * const source = this.map?.getSource(this.id) as mapboxgl.GeoJSONSourceRaw;
   * if (source) {
   *   source.data = this.data;
   * } else {
   *   this.map?.addSource(this.id, { type: "geojson", data: this.data });
   * }
   * ```
   */
  protected abstract updateSource(): void;

  /**
   * Return the layer definitions to be consumed by Mapbox
   */
  abstract buildLayers(): Exclude<mapboxgl.AnyLayer, mapboxgl.CustomLayerInterface>[];

  /**
   * Sets visibility flag & forecast timeIndex, and then updates, or adds to map if not already added
   *
   * @param {boolean} visible
   * @memberof BaseMapLayer
   */
  setVisible(visible: boolean, timeIndex = 0) {
    this.isVisible = visible && timeIndex === this.timeIndex;

    if (this.isVisible) {
      this.setTimer();
    } else {
      this.clearTimer();
      this.popup?.remove();
    }

    // we need to call this to update the map even if the layer is going invisible
    this.update();
  }

  set3dMode(mode: boolean) {}

  /**
   * Returns array of map layer IDs provided by this layer source
   *
   * @param {boolean} [visibleOnly=false] Optional filter to only return visible layer IDs
   * @returns {string[]}
   * @memberof BaseMapLayer
   */
  getMapLayerIds(visibleOnly = false): string[] {
    const layers = visibleOnly
      ? this.layersOnMap.filter((l) => this.map?.getLayoutProperty(l.id, "visibility") === "visible")
      : this.layers;
    return layers.map((l) => l.id);
  }

  /**
   * Load image into map to use in symbol layers
   * Note that load is async so the image is not available to put on the map until the imageSrc is loaded
   *
   * @param {string} imageName The ID to use for the loaded image
   * @param {string} imageSrc URL path to the image to load into the map
   * @returns
   * @memberof BaseMapLayer
   */
  addImageToMap(
    imageName: string,
    imageSrc: string,
    options: { width?: number; height?: number } = { width: 28, height: 28 },
  ) {
    if (!this.map || this.map.hasImage(imageName)) return;
    const { width, height } = options;

    // load the icons with varying size of lozenge
    const image = new Image(width, height);
    image.onload = () => this.map?.hasImage(imageName) || this.map?.addImage(imageName, image);

    // image.src is async so the image is not available to put on the map until the src is set. Thus the callback above
    image.src = imageSrc;
  }

  /**
   * event handler helper to ensure we only add event handlers if not already defined
   *
   * @param type
   * @param layer
   * @param listener
   * @memberof BaseMapLayer
   */
  on<T extends keyof MapLayerEventType>(
    type: T,
    layer: string,
    listener: (ev: MapLayerEventType[T] & EventData) => void,
  ) {
    if (!this.mapService) return;
    // if not already defined, add event handler
    if (!this.events.find(([t, la, li]) => t === type && la === layer && li === listener)) {
      this.mapService.eventHandler?.on(type, layer, listener);
      this.events.push([type, layer, listener]);
    }
    // add event handler to internal array for safe keeping
  }

  /**
   * event handler helper remove only previously defined handlers, or remove all of them
   *
   * @param type
   * @param layer
   * @param listener
   * @memberof BaseMapLayer
   */
  off<T extends keyof MapLayerEventType>(
    type?: T,
    layer?: string,
    listener?: (ev: MapLayerEventType[T] & EventData) => void,
  ) {
    // if no parameters, remove all handlers
    if (!type) {
      this.events.forEach(([t, la, li]) => this.mapService.eventHandler?.off(t, la));
      this.events.length = 0;
      return;
    }
    const index = this.events.findIndex(([t, la, li]) => t === type && la === layer && li === listener);
    if (index) {
      layer && this.mapService.eventHandler?.off(type, layer);
      this.events.splice(index, 1);
    }
  }

  /**
   * Create and add or update the layers on the map for this data source
   */
  protected updateLayers() {
    try {
      if (this.layers.length === 0) this.layers = this.buildLayers();

      this.layers.forEach((layerProps) => {
        const layer = this.map?.getLayer(layerProps.id);
        const targetVisibility = this.isVisible ? "visible" : "none";
        if (layer) {
          // only update the layer property if needed
          if (layer.type !== "custom" && layer.layout?.visibility !== targetVisibility) {
            this.map?.setLayoutProperty(layer.id, "visibility", targetVisibility);
          }
        } else {
          if (this.isVisible) {
            this.map?.addLayer(
              {
                ...layerProps,
                layout: { ...layerProps.layout, visibility: targetVisibility },
              },
              this.beforeLayer(layerProps.id),
            );
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Return the layer ID of the layer this layer should be before (under)
   */
  protected beforeLayer(layerId: string): string | undefined {
    return undefined;
  }

  /**
   * Gets the list of this source's layers currently added to the map
   */
  protected get layersOnMap() {
    return this.layers.filter((l) => this.map?.getLayer(l.id));
  }

  static getIcon(): string {
    return "";
  }

  get popup() {
    return this.mapService?.getPopup();
  }

  /**
   * Called when the update timer triggers.
   */
  protected onTimer = () => {
    this.update();
  };

  private setTimer() {
    this.clearTimer();

    if (this.isVisible && this.updateInterval) {
      this.updateTimer = setInterval(this.onTimer, this.updateInterval);
    }
  }

  private clearTimer() {
    if (!this.updateTimer) return;
    clearInterval(this.updateTimer);
    this.updateTimer = null;
  }

  setLayerOpacity(opacity: number) {
    try {
      this.opacity = opacity;
      this.layers.forEach((l) => {
        const layer = this.map?.getLayer(l.id);
        if (layer) this.map?.setPaintProperty(l.id, `${l.type}-opacity`, opacity);
      });
    } catch (e) {
      console.error(e);
    }
  }

  removeLayer(id: string) {
    try {
      if (this.map?.getLayer(id)) this.map.removeLayer(id);
    } catch (e) {}
  }
}
