/* eslint-disable comma-dangle */
// this file is auto generated, do not edit it directly
module.exports = {
  "Auth": {
    "userPoolId": "us-west-2_oR4A85KRQ",
    "userPoolWebClientId": "4ch78tudplnmfubrsda4p5pf7o",
    "mandatorySignIn": true,
    "authenticationFlowType": "USER_PASSWORD_AUTH"
  },
  "ApiConfig": {
    "host": "beta.api.signal4d.com"
  },
  "AppConfig": {
    "name": "FlightWatch",
    "mapStyle": "mapbox://styles/mapbox/satellite-streets-v11"
  }
}