import buildingIcons from "assets/icons/buildings.svg";
import convert from "common/utils/converter";
import React from "react";

export type BuildingPopupPropsType = {
  height: number;
};

export default function BuildingPopup(props: BuildingPopupPropsType) {
  return (
    <React.Fragment>
      <div className="popup-c flex min-w-[220px]">
        <div className="tab-icon w-[50px] grow cursor-pointer rounded-tl-md rounded-bl-md bg-slate-200 p-2">
          <img className="h-auto w-[30px]" src={buildingIcons} alt="building icons" />
        </div>
        <div className="grow py-2 px-4">
          <p className="mr-2 text-base font-medium">Building Height Alert</p>
          <p className="text-lg font-medium">{convert(props.height).from("m").to("ft").toFixed(2)} ft.</p>
        </div>
      </div>
    </React.Fragment>
  );
}
