import React from "react";
import mapboxgl from "mapbox-gl";
import { renderToStaticMarkup } from "react-dom/server";

export class MapPopup {
  map: mapboxgl.Map;
  popup?: mapboxgl.Popup;
  placeholder: string;
  contentArray: React.ReactElement[] = [];
  rect = {
    width: 0,
    height: 0,
  };
  constructor(map: mapboxgl.Map) {
    this.map = map;
    this.placeholder = "";
  }

  add({ coordinates, content }: { coordinates: mapboxgl.LngLatLike; content: JSX.Element }) {
    // remove any exisiting popup
    this.rect.height = 0;
    this.rect.width = 0;
    const currentLL = this.popup?.getLngLat();
    const newLL = mapboxgl.LngLat.convert(coordinates);
    if (currentLL?.lat === newLL.lat && currentLL?.lng === newLL.lng) {
      // make sure we dont already have this same content in the content array, it not add it
      if (!this.contentArray.find((e) => e.key === content.key)) {
        this.contentArray.push(content);
      }
    } else {
      // this.remove();
      this.contentArray = [content];
    }

    // remove the old one, if one exists
    this.remove();

    this.placeholder = "";
    this.contentArray.forEach((content) => {
      this.placeholder += renderToStaticMarkup(content);
    });

    const popup = new mapboxgl.Popup({ maxWidth: undefined, closeOnClick: true }).setHTML(this.placeholder);

    if (coordinates !== [0, 0]) {
      popup.addTo(this.map).setLngLat(coordinates);
      this.createTabs();
    }

    // now save the new one
    this.popup = popup;

    return popup;
  }

  createTabs() {
    let currentTab: HTMLElement | null;
    const popupEl = document.getElementsByClassName("popup-c");
    const popupContent = document.getElementsByClassName("mapboxgl-popup-content") as HTMLCollectionOf<HTMLElement>;
    if (!popupContent.length) return;
    const h = popupEl.length * 70;
    popupContent[0].classList.add("flex", "flex-row-reverse");
    popupContent[0].style.minHeight = h + "px";
    const div = document.createElement("div");
    div.classList.add("flex", "flex-col", "absolute", "left-[-50px]", "h-full", "items-end");
    popupContent[0].appendChild(div);

    for (let i = 0; i < popupEl.length; i++) {
      const tab = popupEl[i].firstElementChild;
      if (tab) {
        const tabClone = tab.cloneNode(true) as HTMLElement;
        div.appendChild(tabClone);
        const rect = popupEl[i].getBoundingClientRect();
        this.rect.height = Math.max(rect.height, this.rect.height);
        this.rect.width = Math.max(rect.width, this.rect.width);
        popupEl[i].classList.add("hidden");
        tab.remove();
        tabClone.addEventListener("click", (e) => {
          this.tabOnClick(e, tabClone, popupEl[i] as HTMLElement, currentTab);
        });
      }
    }

    const tabs = document.getElementsByClassName("tab-icon") as HTMLCollectionOf<HTMLElement>;
    if (tabs.length) tabs[0].click();
  }

  tabOnClick = (e: Event, currentTab: HTMLElement, popEl: HTMLElement, prevTab?: HTMLElement | null) => {
    const tabs = document.getElementsByClassName("tab-icon");
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("w-[60px]");
    }
    currentTab.classList.add("w-[60px]");
    prevTab = currentTab;
    const popupEl = document.getElementsByClassName("popup-c") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < popupEl.length; i++) {
      popupEl[i].classList.add("hidden");
      popupEl[i].style.height = this.rect.height + "px";
      popupEl[i].style.width = this.rect.width + "px";
    }
    popEl.classList.remove("hidden");
  };

  /**
   * remove any existing popup from the map
   *
   * @memberof MapPopup
   */
  remove = () => {
    if (this.popup) {
      this.popup?.remove();
      delete this.popup;
    }
  };

  on(type: string, listener: (e: Object | undefined) => void) {
    return this.popup?.on(type, listener);
  }

  off(type?: string | any, listener?: (e: Object | undefined) => void) {
    return this.popup?.off(type, listener);
  }

  once(type: string, listener: (e: Object | undefined) => void) {
    return this.popup?.once(type, listener);
  }
}
