import { getSurveyPathFeature } from "flight-plan/helpers/flight-path";
import { FlightPlanJson } from "flight-plan/models";
import { GeojsonLayer, GeojsonLayerProps } from "map/services/geojsonLayer";
import { LineLayer } from "mapbox-gl";
import length from "@turf/length";

export type FlightPathProps = GeojsonLayerProps & {
  flightPlan: FlightPlanJson;
  is3d: boolean;
};

export class FlightPath extends GeojsonLayer {
  flightPlan: FlightPlanJson;
  is3d: boolean;

  constructor(props: FlightPathProps) {
    super(props);
    this.flightPlan = props.flightPlan;
    this.is3d = props.is3d;
    this.data = {
      features: [this.path],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }
  protected async updateData() {}

  buildLayers() {
    return [
      {
        id: `${this.id}-line`,
        type: "line",
        source: this.id,
        paint: {
          "line-opacity": 1,
          "line-color": "#445ffe",
          "line-width": 4,
        },
        layout: {
          visibility: this.isVisible ? "none" : "visible",
        },
      } as LineLayer,
    ];
  }

  set3d(is3d: boolean) {
    this.is3d = is3d;
    this.setVisible(true);
  }

  setVisible(visible: boolean) {
    this.isVisible = visible && !this.is3d;
    this.update();
  }

  updateFlight(flightPlan?: FlightPlanJson) {
    if (flightPlan) this.flightPlan = flightPlan;
    this.data = {
      features: [this.path],
      id: this.id,
      type: "FeatureCollection",
    } as GeoJSON.FeatureCollection;
    this.update();
  }

  get path() {
    // this is a temporary fix to prevent from app from crashing when the path is too long
    const path = getSurveyPathFeature(this.flightPlan);
    const pathLength = length(path, { units: "kilometers" });
    if (pathLength > 10000) {
      return {
        type: "FeatureCollection",
        features: [],
      };
    } else {
      return path;
    }
  }

  remove() {
    this.layers.forEach((layer) => {
      this.removeLayer(layer.id);
    });

    if (this.map?.getSource(this.id)) this.map.removeSource(this.id);
  }

  getId() {
    return this.flightPlan.flightPlanId;
  }
}
