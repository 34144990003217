import mapboxgl, { Anchor, EventData, IControl, LngLatLike, MapEventType, MapLayerEventType } from "mapbox-gl";
import { ControlPosition, MapboxMarker } from "models";
import { DrawControl } from "./drawServices";
import { LayerEventHandler } from "./eventHandler";
import { MapPopup } from "./mapPopup";
import type { Feature } from "@turf/turf";
import bbox from "@turf/bbox";
import { DrawEventType } from "@mapbox/mapbox-gl-draw";
import { getLocalStorageItem, saveToLocalStorage } from "common/adapters/localStorage";
import GitInfo from "react-git-info/macro";

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";
const S4D_API_KEY = process.env.REACT_APP_S4D_API_KEY || "";
mapboxgl.accessToken = accessToken;
const isDebug = process.env.NODE_ENV === "development";

interface MapServiceProps {
  mapViewState: typeof defaultMapViewState;
}

const defaultMapViewState = getLocalStorageItem("mapState") || {
  longitude: -122.45,
  latitude: 37.78,
  zoom: 10,
  bearing: 0,
  pitch: 0,
};

export let mapService: MapService;

export type MarkerRecordType = {
  marker: MapboxMarker;
  callback?: () => void;
};

export class MapService {
  map: mapboxgl.Map | null = null;
  onLoaded?: () => void;
  popup?: MapPopup;
  controls: IControl[] = [];
  draw?: DrawControl;
  eventHandler?: LayerEventHandler;
  markers: MarkerRecordType[] = [];

  constructor(public props: MapServiceProps = { mapViewState: defaultMapViewState }) {
    // save to global variable for now - need to find a better solution
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    mapService = this;
  }

  initMap(mapContainer: HTMLElement | null) {
    if (!mapContainer) return;
    this.map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/satellite-streets-v11?optimize=true",
      center: [this.props.mapViewState.longitude, this.props.mapViewState.latitude],
      zoom: this.props.mapViewState.zoom,
      bearing: this.props.mapViewState.bearing,
      pitch: this.props.mapViewState.pitch,
      hash: "ll",
      attributionControl: false,
      testMode: true,
      transformRequest: (url, resourceType) => {
        if (resourceType === "Tile" && url.includes("api.signal4d")) {
          return {
            url: url,
            headers: {
              "x-api-key": S4D_API_KEY,
              accept: "application/x-protobuf",
            },
          };
        } else {
          return { url };
        }
      },
    });

    this.map.on("load", () => this.onLoadedInternal());
    this.map.on("remove", () => this.remove());
    this.popup = new MapPopup(this.map);
    this.draw = new DrawControl(this);
    window.addEventListener("resize", this.onResize);
    return this.map;
  }

  private onLoadedInternal() {
    if (this.map) this.eventHandler = new LayerEventHandler(this.map);
    this.addControl(new mapboxgl.NavigationControl(), "top-right");
    this.addControl(new mapboxgl.GeolocateControl(), "top-right");
    let branch = "";
    try {
      const gitInfo = GitInfo();
      if (gitInfo.branch !== "main") branch = `.${gitInfo.branch}.${gitInfo.commit.shortHash}`;
    } catch (e) {}
    this.map?.addControl(
      new mapboxgl.AttributionControl({
        customAttribution: `v${process.env.REACT_APP_VERSION}${branch} | <a href="https://signal4d.com" target="_blank">&copy; Signal4D, Inc</a>`,
        compact: true,
      }),
    );
    this.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector("body") }), "top-right");
    this.map?.on("click", (e) => {
      if (isDebug && this.map) {
        const features = this.map?.queryRenderedFeatures(e.point);
        console.log(features);
      }
    });
    this.saveMapState();
    this.onLoaded?.();
  }

  onResize = () => {
    this.map?.resize();
  };

  private saveMapState() {
    this.map?.on("moveend", (e) => {
      const map = e.target;
      const mapState = {
        longitude: map.getCenter().lng,
        latitude: map.getCenter().lat,
        zoom: map.getZoom(),
        bearing: map.getBearing(),
        pitch: map.getPitch(),
      };
      saveToLocalStorage("mapState", mapState);
    });
  }

  getMap() {
    return this.map;
  }

  flyToLocation(center: LngLatLike, zoom?: number) {
    this.map?.flyTo({ center: center, zoom: zoom ? zoom : 19 });
  }

  remove() {
    if (!this.map) return;
    try {
      this.controls.forEach((c) => this.removeControl(c));
      this.map?.remove();
      this.map = null;
      window.removeEventListener("resize", this.onResize);
    } catch (e) {}
  }

  /****************
   *
   * MapBox Events
   *
   ***************/

  on<T extends keyof (MapLayerEventType | MapEventType | DrawEventType)>(
    type: T,
    listener: (ev: (MapLayerEventType[T] | MapEventType[T]) & EventData) => void,
  ) {
    if (!this.map) return;

    this.map.on(type, listener);
    return this;
  }

  off<T extends keyof (MapLayerEventType | MapEventType | DrawEventType)>(
    type: T,
    listener: (ev: MapLayerEventType[T] & EventData) => void,
  ): this {
    if (!this.map) return this;

    this.map.off(type, listener);
    return this;
  }

  /****************
   *
   * Custom Events
   *
   ***************/

  getDraw() {
    return this.draw;
  }

  /****************
   *
   * Popups
   *
   ***************/

  getPopup() {
    return this.popup;
  }

  removePopup() {
    try {
      if (this.popup) this.popup.remove();
    } catch (e) {}
  }

  /****************
   *
   * Controls
   *
   ***************/

  addControl(control: IControl, position: ControlPosition) {
    if (!this.map) return;

    try {
      if (!this.map.hasControl(control)) this.map.addControl(control, position);
      this.controls.push(control);
    } catch (err) {
      console.log(err);
    }
  }

  removeControl(control: IControl) {
    if (!this.map) return;

    try {
      this.map.removeControl(control);
      this.controls = this.controls.filter((c) => c !== control);
    } catch (err) {
      console.log(err);
    }
  }

  addImageToMap(
    imageName: string,
    imageSrc: string,
    options: { width?: number; height?: number } = { width: 50, height: 50 },
  ) {
    if (!this.map || !this.map.isStyleLoaded || !imageName || this.map.hasImage(imageName)) return;

    try {
      const { width, height } = options;
      const image = new Image(width, height);
      image.onload = () => this.map?.hasImage(imageName) || this.map?.addImage(imageName, image);

      // image.src is async so the image is not available to put on the map until the src is set. Thus the callback above
      image.src = imageSrc;
    } catch (e) {
      console.log(e);
    }
  }

  // Map Navigation

  fitTo(feature: Feature) {
    const [x1, y1, x2, y2] = bbox(feature);
    this.map?.fitBounds([x1, y1, x2, y2], { padding: 20, bearing: this.map?.getBearing() });
  }

  // Markers
  addMarker(coordinates: LngLatLike, anchor: Anchor, html: HTMLElement, callback?: () => void) {
    if (!this.map) return;
    const marker = new mapboxgl.Marker(html).setLngLat(coordinates).addTo(this.map);
    this.markers.push({
      marker: marker,
      callback: callback,
    });
    return marker;
  }

  removeMarker(marker?: MapboxMarker) {
    if (marker) {
      const index = this.markers.findIndex((item) => item.marker === marker);
      if (index >= 0) {
        this.markers = [...this.markers.slice(0, index), ...this.markers.slice(index + 1)];
      }
      return marker.remove();
    }
    this.markers.forEach((marker) => {
      if (marker.callback) marker.marker.remove();
    });
  }
}
