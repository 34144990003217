import React, { ReactElement } from "react";
import alertTriangleIcon from "assets/icons/alert-triangle.svg";

export interface FaaFlightRestriction {
  OBJECTID: number;
  Proponent?: string; // "DOD"
  Branch?: string; // "USAF"
  Base?: string; // "Cheyenne Mountain Air Force Station";
  Facility?: string; // "Cheyenne Mountain Complex 6";
  Airspace?: string; // "G";
  Reason?: string; // "NATIONAL SECURITY";
  State?: string; // "CO";
  FAA_ID?: string; // "20170518-DOD-Cheyenne Mountain Air Force Station-Cheyenne Mountain Complex 6";
  POC?: string; // "721 CES: 719-474-2074; 721 SFS: 719-474-3014     Emergency: 721 Base Defense Ops: 719-474-3311";
  Floor?: string; // "Surface";
  Ceiling?: string; // "400' AGL";
  CEILING?: number; // 400
  ceilingMeters?: number; // S4D normalized ceiling value in meters
  County?: string; // "EL PASO";
  Shape__Area: number; // 0.00018188681769970599;
  Shape__Length: number; // 0.078986485522206995;
  APT1_NAME?: string;
  APT1_LAANC: number;
}

export interface FaaProhibitedRegion {
  AK_HIGH: number;
  AK_LOW: number;
  ceilingMeters: number;
  CITY: string;
  COUNTRY: string;
  dataset: string;
  DST_CODE: string;
  EXCLUSION: string;
  GMTOFFSET: string;
  id: string;
  LEVEL_CODE: string;
  LOWER_CODE: string;
  LOWER_UOM: string;
  LOWER_VAL: string;
  name: string;
  ONSHORE: string;
  PACIFIC: number;
  STATE: string;
  TIMESOFUSE: string;
  type: string;
  TYPE_CODE: string;
  UPPER_CODE: string;
  UPPER_DESC: string;
  UPPER_UOM: string;
  UPPER_VAL: string;
  US_AREA: number;
  US_HIGH: number;
  US_LOW: number;
}

export type RestrictionPropType = Partial<FaaFlightRestriction & FaaProhibitedRegion>;

export function FlightRestrictionPopup(props: { restrictionProps: RestrictionPropType; id: string }): ReactElement {
  const { restrictionProps } = props;

  const bgColor = "#eaa8a8";

  const issuedBy = restrictionProps.Proponent ?? "FAA";

  const ceilingFeet = Math.round((restrictionProps.ceilingMeters ?? 0) * 3.28);

  return (
    <div className="popup-c flex min-w-[220px] max-w-[500px]" key={props.id}>
      <div
        className="tab-icon w-[50px] grow cursor-pointer rounded-tl-md rounded-bl-md p-2"
        style={{ backgroundColor: bgColor }}>
        <img src={alertTriangleIcon} alt="alert-img" className="w-[30px]" />
      </div>
      <div className="grow py-2 px-4">
        <div>
          <div className="text-base font-medium">UAS Flight Restriction</div>
          <div className="text-base">
            <p>Ceiling: {ceilingFeet}'</p>
          </div>
        </div>
        <hr />
        <div className="text-sm font-medium">
          {restrictionProps.type === "Prohibited" ? "Prohibited Airspace" : restrictionProps.Reason}
        </div>
        <div className="detail">
          {/* {showProp(restrictionProps.type === "Prohibited" ? "Prohibited Airspace" : restrictionProps.Reason, "Reason")} */}
          {showProp("Times of use", restrictionProps.TIMESOFUSE)}
          {showProp("Facility", restrictionProps.Facility)}
          {showProp("Contact", restrictionProps.POC)}
          {showProp("FAA ID", restrictionProps.FAA_ID)}
          {showProp("Airport", restrictionProps.APT1_NAME)}
          {showProp("LAANC enabled", restrictionProps.APT1_LAANC === 1 ? "Yes" : "No")}
        </div>
        <div className="timeline">
          {showProp("Issued by", issuedBy, { marginBottom: 0 })}
          {showProp("Branch", restrictionProps.Branch, { marginBottom: 0 })}
          {showProp("Base", restrictionProps.Base)}
        </div>
      </div>
    </div>
  );
}

function showProp(title: string, prop: string | undefined, style?: React.CSSProperties): React.ReactNode {
  return (
    prop && (
      <p style={style}>
        <strong>{title}:&nbsp;</strong>
        {prop}
      </p>
    )
  );
}

export default FlightRestrictionPopup;
