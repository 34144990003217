import { Auth } from "aws-amplify";
import amplifyConfig from "amplifyConfig";

export const isAW = window.location.hostname.includes("airwise");

export const API = {
  API: {
    endpoints: [
      {
        name: "Dashboard-API",
        endpoint: `https://${amplifyConfig.ApiConfig.host}`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  },
};

export const authConfig = { Auth: amplifyConfig.Auth };
Auth.configure({ ...authConfig, ...API });
