import { LngLatLike } from "mapbox-gl";

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

export interface Place {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: Properties;
  text: string;
  place_name: string;
  bbox: number[];
  center: LngLatLike;
  geometry: Geometry;
  context: Context[];
}

export interface Context {
  id: string;
  short_code: string;
  wikidata: string;
  text: string;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface Properties {
  wikidata: string;
}

export const fetchPlaces = (place: string): Promise<Place[]> => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
    place,
  )}.json?&types=place,address&access_token=${accessToken}`;
  return fetch(url)
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res.features;
    });
};
