import { makeRequest } from "common/adapters/makeRequest";
import type { FlightPlan, FlightPlanJson } from "../../flight-plan/models";

const BASE_PATH = "/locations/oa/v2/flights";

export const postFlightPlan = async (flightPlanJson: FlightPlanJson) => {
  return makeRequest({
    method: "POST",
    path: BASE_PATH,
    authType: "oauth",
    body: flightPlanJson,
  })
    .then((res: FlightPlanJson) => {
      return res;
    })
    .catch((err: any) => {
      throw new Error(err);
    });
};

export const updateFlightPlan = async (flightPlanJson: FlightPlanJson, id: string) => {
  return makeRequest({
    method: "POST",
    path: `${BASE_PATH}/${id}`,
    authType: "oauth",
    body: flightPlanJson,
  })
    .then((res: FlightPlanJson) => {
      return res;
    })
    .catch((err: any) => {
      throw new Error(err);
    });
};

export const getFlightPlans = async () => {
  return makeRequest({
    method: "GET",
    path: BASE_PATH,
    authType: "oauth",
    headers: {
      "Content-Type": "application/json",
    },
    query: {
      limit: "200",
    },
  })
    .then((res: FlightPlan[]): FlightPlan[] => {
      return res?.length ? res : [];
    })
    .catch((err: any) => {
      throw err;
    });
};

export const getPlanFileById = async (id: string) => {
  return makeRequest({
    method: "GET",
    path: `${BASE_PATH}/${id}`,
    authType: "oauth",
    headers: {
      "Content-Type": "application/json",
    },
    query: {
      format: "Plan",
    },
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      throw err;
    });
};

export const getFlightPlanById = async (id: string) => {
  return makeRequest({
    method: "GET",
    path: `${BASE_PATH}/${id}`,
    authType: "oauth",
    headers: {
      "Content-Type": "application/json",
    },
    query: {
      format: "geojson",
    },
  })
    .then((res: FlightPlanJson) => {
      return res;
    })
    .catch((err: any) => {
      throw err;
    });
};

export const deleteFlightPlanById = async (id: string) => {
  return makeRequest({
    method: "DELETE",
    path: `${BASE_PATH}/${id}`,
    authType: "oauth",
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      throw err;
    });
};
