import React, { useContext, useState } from "react";
import toGeoJSON from "@mapbox/togeojson";
import { FilePond } from "react-filepond";
import type { FilePondErrorDescription } from "filepond";
import "filepond/dist/filepond.min.css";
import { FlightPlanJson } from "../models";
import { defaultFlightMetadata, FlightPlanGenerator, getSurveyBoundary } from "../helpers/flight-path";
import { postFlight, FlightsContext } from "../store";
import { calculateFootprint, generateLinePath, getCentroid } from "flight-plan/helpers";
import type { Feature, Polygon } from "@turf/turf";
import { generateUuid } from "common/utils/generateUuid";
import { AppContext } from "App";
import { useMap } from "map/services/useMap";

export default function UploadPath() {
  const map = useMap();
  const [files, setFiles] = useState([]);
  const { dispatch } = useContext(FlightsContext);
  const { setError } = useContext(AppContext);

  const handleFileAdd = (err: FilePondErrorDescription | null, file: any) => {
    if (err) throw new Error(err.type);
    const reader = new FileReader();
    reader.readAsText(file.file);

    reader.onload = function () {
      const kml = reader.result as string;
      if (kml) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(kml, "text/xml");
        const kmlToGeoJson = toGeoJSON.kml(xmlDoc);
        save(kmlToGeoJson.features[0], file.filenameWithoutExtension);
      }
      setFiles([]);
    };
  };

  const save = async (feature: Feature<Polygon>, filename: string) => {
    try {
      const flightMetadata = defaultFlightMetadata;
      const { adjustedFootprintFront, adjustedFootprintSide } = calculateFootprint(flightMetadata);
      flightMetadata.cameraMetadata.adjustedFootprintFrontal = adjustedFootprintFront;
      flightMetadata.cameraMetadata.adjustedFootprintSide = adjustedFootprintSide;
      const lineStringFeatureCollection = generateLinePath(flightMetadata, feature);
      const center = getCentroid(feature).geometry.coordinates;
      const flightPlanJson: FlightPlanJson = FlightPlanGenerator(
        generateUuid(),
        filename,
        flightMetadata,
        feature.geometry,
        lineStringFeatureCollection.geometry,
        center,
      );
      flightPlanJson.lastUpdated = new Date();

      if (flightPlanJson && flightPlanJson.name.length > 0) {
        await postFlight(flightPlanJson);
        map?.fitTo(getSurveyBoundary(flightPlanJson));
        dispatch({
          type: "ADD",
          payload: flightPlanJson,
        });
      }
    } catch (e) {
      setError(e as string);
    }
  };

  return (
    <React.Fragment>
      <FilePond
        files={files}
        onaddfile={(e, file): void => handleFileAdd(e, file)}
        allowMultiple={true}
        maxFiles={3}
        server={"/"}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </React.Fragment>
  );
}
