/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { convert, Units } from "common/utils/converter";

export interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  value: string | number;
  onUpdate: Function;
  units: Units[];
  defaultUnit: Units;
  displayUnit: Units;
  half?: Boolean;
}

export default function InputField(props: InputFieldProps) {
  const { label, type, name, value, onUpdate, units, defaultUnit, displayUnit } = props;
  const [convertedValue, setConvertedValue] = useState<string | number>(+value);
  const [currentUnit, setCurrentUnit] = useState<Units>(defaultUnit);

  useEffect(() => {
    unitChange(displayUnit);
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) return setConvertedValue("");
    setConvertedValue(+e.target.value);
    const defaultValue =
      units.length > 1
        ? convert(+e.target.value)
            .from(currentUnit)
            .to(defaultUnit)
        : e.target.value;
    onUpdate(defaultValue);
  };

  const unitChange = (newUnit: Units) => {
    if (units.length < 2) return;
    const newValue = convert(+convertedValue)
      .from(currentUnit)
      .to(newUnit);
    setConvertedValue(+newValue);
    setCurrentUnit(newUnit);
  };

  return (
    <div>
      <label className="label mt-1 py-0">
        <span className="label-text">{label}</span>
      </label>
      <label className="input-group">
        <input
          className={"input-bordered input-sm w-full"}
          type={type}
          name={name}
          placeholder={name}
          value={
            convertedValue >= 0
              ? typeof convertedValue === "number"
                ? +convertedValue.toFixed(2)
                : convertedValue
              : ""
          }
          onChange={(e) => onInputChange(e)}
        />
        {units.map(
          (unit, index) =>
            unit === currentUnit && (
              <span
                className="cursor-pointer"
                key={index}
                onClick={() => unitChange(units[(index + 1) % units.length])}>
                {unit}
              </span>
            ),
        )}
      </label>
    </div>
  );
}
