import { AppContext } from "App";
import React, { useContext } from "react";

export default function ErrorDialog() {
  const { error, setError } = useContext(AppContext);

  const onClose = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <input type="checkbox" id="my-modal-4" className="modal-toggle" checked={!!error} readOnly />
      <div className="modal cursor-pointer">
        <div className="modal-box relative">
          <button className="btn btn-circle btn-sm absolute right-2 top-2" onClick={onClose}>
            ✕
          </button>
          <h3 className="text-2xl font-bold">Error</h3>
          <p className="py-4 text-lg">{error?.toString()}</p>
          <div className="modal-action justify-start">
            <button className="btn btn-outline" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
