import { BaseMapLayer, BaseMapLayerProps } from "map/services/baseMapLayer";

export type VectorTileLayerProps = BaseMapLayerProps;

export abstract class VectorTileLayer extends BaseMapLayer {
  async update() {
    if (!this.map) return;

    try {
      if (this.isVisible && this.map.getStyle()) {
        this.updateSource();
      }

      this.updateLayers();
    } catch (err) {
      console.error(`Unable to update layer: ${this.id}`, (err as Error).message);
    }
  }

  protected beforeLayer(layerId: string): string | undefined {
    const roadLayers = this.map
      ?.getStyle()
      .layers?.filter(
        (layer) => layer.type !== "custom" && layer["source-layer"] === "road" && layer.id.includes("label"),
      );
    return roadLayers?.[0].id;
  }

  protected updateSource() {
    // since the tile URL is static for this, we can short-circuit unnecessary updates
    if (this.map?.getSource(this.id)) return;

    this.map?.addSource(this.id, this.getSourceProps());
  }

  protected abstract getSourceProps(): mapboxgl.VectorSource;
}
