import React from "react";

export type IconProps = {
  source: string;
  size: number;
  onClick?: () => void;
};

// Need to refactor this to support svg
export default function Icon(props: IconProps) {
  const { source, size } = props;

  if (source) {
    return <img src={source} className={`h-[${size}]`} alt={source} />;
  } else {
    return null;
  }
}
