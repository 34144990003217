export const saveToLocalStorage = (id: string, jsonItem: Object) => {
  try {
    const body = JSON.stringify(jsonItem);
    localStorage.setItem(id, body);
  } catch (err) {
    console.log(err);
  }
};

export const getLocalStorageItem = (id: string) => {
  try {
    const body = localStorage.getItem(id);
    if (body) {
      return JSON.parse(body);
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};
