import { AppContext } from "App";
import { useContext } from "react";
import { Link } from "react-router-dom";
import UserIcon from "assets/nav-icons/user.svg";

export default function UserCard() {
  const { user } = useContext(AppContext);

  return (
    <div className="w-[200px] rounded-lg bg-white">
      <div className="flex border-b p-2">
        <img src={UserIcon} alt="drone icon" className="mr-1 h-[22px]" />
        <p className="font-medium">User Account</p>
      </div>
      <div className="p-2">
        <p>
          Signed in as <span>{user.email}</span>
        </p>
        <div className="divider"></div>
        <Link className="btn" to={"/logout"}>
          Sign Out
        </Link>
      </div>
    </div>
  );
}
