// this implementation is taken from the docs at https://github.com/convert-units/convert-units#readme
// Specifically from 3.0.0-beta.4
// https://github.com/convert-units/convert-units/blob/aad3b049a24834c0d5e7006de79154b6ded59b1a/README.md
import configureMeasurements, {
  area,
  AreaSystems,
  AreaUnits,
  length,
  LengthSystems,
  LengthUnits,
  Measure,
  SpeedUnits,
  TimeUnits,
  SpeedSystems,
  TimeSystems,
  speed,
  time,
} from "convert-units";

type NewLengthUnits = LengthUnits | "px";
const DPI = 96;
const extendedLength: Measure<LengthSystems, NewLengthUnits> = {
  systems: {
    metric: {
      ...length.systems.metric,
      px: {
        name: {
          singular: "Pixel",
          plural: "Pixels",
        },
        to_anchor: 0.0254 / DPI,
      },
    },
    imperial: {
      ...length.systems.imperial,
    },
  },
  anchors: {
    ...length.anchors,
  },
};
// Measures: The names of the measures being used
type Measures = "length" | "speed" | "time" | "area";
// Systems: The systems being used across all measures
type Systems = LengthSystems | SpeedSystems | TimeSystems | AreaSystems;
// Units: All the units across all measures and their systems
export type Units = NewLengthUnits | SpeedUnits | TimeUnits | AreaUnits | "%";
export const convert = configureMeasurements<Measures, Systems, Units>({ length: extendedLength, speed, time, area });

export default convert;
