import type { Feature, Geometry } from "@turf/turf";
export const SurveyBoundaryType = "SurveyBoundary";
export const SurveyPathType = "FlightRoute";
export const HomePointType = "HomePoint";

export interface LayerState extends FlightPlanJson {
  isEditing: boolean;
  isVisible: boolean;
}

export interface FootPrint {
  imageFootprintSide: number;
  imageFootprintFront: number;
  adjustedFootprintSide: number;
  adjustedFootprintFront: number;
}

export const defaultFootPrintSettings: FootPrint = {
  imageFootprintSide: 0,
  imageFootprintFront: 0,
  adjustedFootprintSide: 0,
  adjustedFootprintFront: 0,
};

export interface CameraMetadata {
  adjustedFootprintFrontal: number;
  adjustedFootprintSide: number;
  cameraName: string;
  distanceMode: number;
  distanceToSurface: number;
  distanceToSurfaceRelative?: boolean;
  fixedOrientation: boolean;
  focalLength: number;
  frontalOverlap: number;
  imageDensity: number;
  imageHeight: number;
  imageWidth: number;
  landscape: boolean;
  minTriggerInterval: number;
  sensorHeight: number;
  sensorWidth: number;
  sideOverlap: number;
  valueSetIsDistance: boolean;
  version: number;
  batteryLife: number;
  flightSpeed: number;
  gimbal: boolean;
  pitch: number;
  yaw: number;
}

export interface FlightBoundaryFeature extends Feature {
  type: "Feature";
  properties: {
    type: "FlightSupport";
    flightType: "Survey";
    flightItemType: "SurveyBoundary";
    flightId: string;
  };
  geometry: Geometry;
}

export interface FlightMetadata {
  defaultAltitude: number;
  altitudeMode: "relativeToLaunch" | "amsl" | "calculatedAboveTerrain";
  triggerDistance: number;
  turnAroundDistance: number;
  orientation: number;
  cameraMetadata: CameraMetadata;
}

export interface FlightPlanJson {
  type: "FeatureCollection";
  fileType: "geojson";
  version: number;
  name: string;
  flightPlanId: string;
  collectionId?: string;
  collectionName?: string;
  flightMetadata: FlightMetadata;
  lastUpdated?: Date;
  features: Feature[];
}

export interface FlightPlan {
  collectionId?: string;
  flightPlanId: string;
  lastUpdated?: Date;
  name: string;
}
