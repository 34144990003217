import { makeRequest } from "common/adapters/makeRequest";
import type { FeatureCollection } from "@turf/turf";
import weatherAlertProductsByPhenomena from "data/weatherAlertProductsByPhenomena.json";

export type WeatherAlertType = "severe" | "flood" | "winter" | "tropical" | "other";

export type WeatherAlertProduct = {
  id: string;
  productCode: string;
  color: string;
};
export const weatherAlertProducts: Record<WeatherAlertType, WeatherAlertProduct> = {
  severe: { id: "weather-severe", productCode: "643", color: "#ff0000" },
  flood: { id: "weather-flood", productCode: "644", color: "#35af6d" },
  winter: { id: "weather-winter", productCode: "645", color: "#0000ff" },
  tropical: { id: "weather-tropical", productCode: "647", color: "#ffa500" },
  other: { id: "weather-other", productCode: "646", color: "#c0c0c0" },
};

export const lookupProductByPhenomena = (phenomenaCode: string): WeatherAlertType => {
  return (weatherAlertProductsByPhenomena as Record<string, WeatherAlertType>)[phenomenaCode] ?? "other";
};

export const fetchWeatherAlerts = async () => {
  try {
    const res: FeatureCollection = await makeRequest({
      method: "GET",
      path: `/weatheralerts/v2/geography/US`,
      authType: "apiKey",
      headers: {
        "Content-Type": "application/json",
      },
      query: { format: "geojson" },
    });
    if (!res?.features?.length) return { type: "FeatureCollection", features: [] };
    res.features.forEach((feature) => {
      if (!feature.properties) return;
      const type = lookupProductByPhenomena(feature.properties.phenomena);
      feature.properties.color = weatherAlertProducts[type].color;
      feature.properties.weatherAlertType = type;
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
