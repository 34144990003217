import React, { ChangeEvent, useEffect, useState } from "react";
import { fetchPlaces, Place } from "./searchService";

export interface SearchProps {
  placeholderText: string;
  successCallback: (place: Place) => void;
}

const defaultProps: SearchProps = {
  placeholderText: "Search for location",
  successCallback: (p: Place) => {},
};

export function Search(props: SearchProps) {
  const placeAutocompleteList: Place[] = [];
  const [displayPlace, setDisplayPlace] = useState("");
  const [place, setPlace] = useState("");
  const [placeAutocomplete, setPlaceAutocomplete] = useState(placeAutocompleteList);

  useEffect(() => {
    setPlaceAutocomplete([]);
    if (place.length > 2) {
      fetchPlaces(place)
        .then((res: Place[]) => {
          setPlaceAutocomplete(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [place]);

  const placeLookup = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayPlace("");
    const { value } = e.target;
    setPlace(value);
  };

  const placeSelect = (place: Place) => {
    setPlace("");
    setDisplayPlace(place.place_name);
    setPlaceAutocomplete([]);
    props.successCallback(place);
  };

  return (
    <div className="h-[50px] w-[280px] rounded-lg bg-white p-2">
      <div className="flex items-center">
        <span className="flex items-center align-middle">
          <SearchIcon size={16} />
        </span>
        <input
          type="text"
          className="ml-1 w-full appearance-none border-0 bg-transparent p-1 shadow-none outline-none focus:border-0 focus:shadow-none focus:outline-0 focus-visible:outline-none"
          placeholder={props.placeholderText}
          value={displayPlace.length ? displayPlace : place}
          onChange={(e) => {
            placeLookup(e);
          }}
        />
      </div>

      {placeAutocomplete.length > 0 && (
        <div className="mt-5 w-full rounded-lg bg-white p-2">
          {placeAutocomplete.map((place, index) => (
            <div className="flex items-start border-b p-1" key={index} onClick={() => placeSelect(place)}>
              <MapPinIcon className="mt-1 mr-1" />
              <p>{place.place_name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const MapPinIcon = ({ color = "currentColor", size = 16, ...rest }) => (
  <svg
    xmlns="http=//www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}>
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
    <circle cx="12" cy="10" r="3" />
  </svg>
);

const SearchIcon = ({ color = "currentColor", size = 24, ...rest }) => (
  <svg
    xmlns="http=//www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}>
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
  </svg>
);

Search.defaultProps = defaultProps;

export default Search;
