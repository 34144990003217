/* eslint-disable react-hooks/exhaustive-deps */
import FlightApi from "common/adapters/flightApi";
import { fetchQuery } from "common/query/query";
import { getSurveyBoundary } from "flight-plan/helpers/flight-path";
import { FlightPlanJson } from "flight-plan/models";
import { CeilingAlerts, FlightCeilingAlerts } from "models/flight";
import { useEffect, useMemo, useState } from "react";

export default function useCeilingAlerts(flight: FlightPlanJson) {
  const [alert, setAlert] = useState<FlightCeilingAlerts | null>(null);

  const boundary = useMemo(() => {
    return getSurveyBoundary(flight);
  }, [flight]);

  const coordString = useMemo(() => {
    if (boundary) {
      const coordinates = boundary.geometry.coordinates;
      const coordString = coordinates.map((c) => c.map((cc) => cc.slice(0, 2)).join(";")).join(";");
      return coordString;
    }
  }, [boundary]);

  useEffect(() => {
    setAlert(null);
    const getAlerts = async (coordString: string) => {
      const res = await getCeilingAlerts(flight.flightPlanId, coordString);
      res.alerts = res.alerts.filter((alert: CeilingAlerts, index: number, self: CeilingAlerts[]) => {
        return index === self.findIndex((t) => t.ceilingMeters === alert.ceilingMeters && t.name === alert.name);
      });
      setAlert(res);
    };
    if (coordString) getAlerts(coordString);
  }, [coordString]);

  return alert;
}

export const getCeilingAlerts = async (id: string, coordString: string) => {
  const defaultTime = 24 * 60 * 60 * 1000;
  const queryKey = `ceiling-alert-${id}`;
  const res = await fetchQuery(
    queryKey,
    FlightApi.fetchCeilingAlertsForCollection(coordString),
    defaultTime,
    defaultTime,
  );
  return res;
};
