import { Position } from "@turf/turf";
import convert from "common/utils/converter";
import { getHomePoint } from "flight-plan/helpers/flight-path";
import { FlightPlanJson } from "flight-plan/models";
import { MapService } from "map/services/mapService";
import { MapboxMarker } from "models";

export type HomePointProps = {
  id: string;
  mapService: MapService;
  flightPlan: FlightPlanJson;
  visible?: boolean;
  editable?: boolean;
  callback?: (coord: number[]) => void;
};

export class HomePoint {
  mapService: MapService;
  flightPlan: FlightPlanJson;
  position: Position;
  marker?: MapboxMarker;
  editable: boolean;
  callback?: (coord: number[]) => void;
  constructor(props: HomePointProps) {
    const { mapService, flightPlan, editable = false, callback } = props;
    if (this.marker) this.marker.remove();
    this.mapService = mapService;
    this.flightPlan = flightPlan;
    this.position = getHomePoint(this.flightPlan);
    this.editable = editable;
    this.callback = callback;
    this.marker = this.mapService.addMarker(
      { lng: this.position[0], lat: this.position[1] },
      "bottom",
      this.buildHomePoint(),
    );
    this.marker?.setDraggable(this.editable);
    this.onDrag();
  }

  buildHomePoint() {
    const div = document.createElement("div");
    div.classList.add("flex", "bg-white", "p-1", "rounded-full", "items-center");
    const p = document.createElement("p");
    p.innerText = "T";
    p.classList.add(
      "border",
      "rounded-full",
      "flex",
      "items-center",
      "justify-center",
      "h-[30px]",
      "w-[30px]",
      "bg-purple-600",
      "text-white",
    );
    const span = document.createElement("span");
    span.classList.add("mx-1");
    span.innerHTML = "Takeoff";
    div.appendChild(p);
    if (this.editable) div.appendChild(span);
    return div;
  }

  onDrag() {
    if (this.marker && this.mapService) {
      this.marker.on("dragend", () => {
        const lngLat = this.marker?.getLngLat();
        if (!lngLat) return;
        let elevation = this.mapService?.getMap()?.queryTerrainElevation(lngLat, { exaggerated: false });
        elevation = elevation
          ? convert(+elevation)
              .from("m")
              .to("ft")
          : 0;
        if (this.callback) this.callback([lngLat.lng, lngLat.lat, elevation]);
      });
    }
    if (this.marker && this.mapService && this.callback) {
      const lngLat = this.marker.getLngLat();
      let elevation = this.mapService.getMap()?.queryTerrainElevation(lngLat, { exaggerated: false });
      elevation = elevation
        ? convert(+elevation)
            .from("m")
            .to("ft")
        : 0;
      this.callback([lngLat.lng, lngLat.lat, elevation]);
    }
  }

  setVisible(visible: boolean) {
    const el = this.marker?.getElement();
    if (visible) {
      if (el) el.classList.remove("hidden");
    } else {
      if (el) el.classList.add("hidden");
    }
  }

  update(flight: FlightPlanJson) {
    this.flightPlan = flight;
    this.position = getHomePoint(this.flightPlan);
    this.marker?.setLngLat({ lng: this.position[0], lat: this.position[1] });
  }

  remove() {
    if (this.marker) this.marker.remove();
  }

  getId() {
    return this.flightPlan.flightPlanId;
  }
}
