import React, { SVGProps } from "react";
// import { MapLayerType } from "map/services/mapLayerProducts";
import { WeatherEventId } from "models/forecast";
import { ReactComponent as TxWXThresholds } from "assets/icons/tx_WXThresholds.svg";
import { ReactComponent as TxWXAlerts } from "assets/icons/tx_WXAlerts.svg";
import { ReactComponent as TxTFIncidents } from "assets/icons/tx_TFIncidents.svg";
import { ReactComponent as TxTFConstruction } from "assets/icons/tx_TFConstruction.svg";
import { ReactComponent as TxTFCurrent } from "assets/icons/tx_TFCurrent.svg";
import { ReactComponent as TxTFTraffic } from "assets/icons/tx_TFPredictive.svg";
import { ReactComponent as TxWXFlood } from "assets/icons/tx_WXFlood.svg";
import { ReactComponent as TxWXOther } from "assets/icons/tx_WXOther.svg";
import { ReactComponent as TxWXTornado } from "assets/icons/tx_WXTornado.svg";
import { ReactComponent as TxWXTropical } from "assets/icons/tx_WXTropical.svg";
import { ReactComponent as TxWXWinter } from "assets/icons/tx_WXWinter.svg";
import { ReactComponent as TxWXFreezing24Hrs } from "assets/icons/tx_freezing24hrs.svg";
import { ReactComponent as TxFreezingNow } from "assets/icons/tx_FreezingNow.svg";
import { ReactComponent as TxSnowLastHour } from "assets/icons/tx_SnowLastHour.svg";
import { ReactComponent as TxSnowFuture } from "assets/icons/tx_SnowFuture.svg";
import { ReactComponent as TxRainFuture } from "assets/icons/tx_RainFuture.svg";
import { ReactComponent as TxWindFuture } from "assets/icons/tx_WindFuture.svg";
import { ReactComponent as TxFreezingFuture } from "assets/icons/tx_FreezingFuture.svg";
import { ReactComponent as TxFireAlerts } from "assets/icons/tx_WXFireAlerts.svg";
import { ReactComponent as WxRain } from "assets/icons/rain.svg";
import { ReactComponent as WxSnow } from "assets/icons/snow.svg";
import { ReactComponent as WxTempHigh } from "assets/icons/highTemp.svg";
import { ReactComponent as WxTempLow } from "assets/icons/temp.svg";
import { ReactComponent as WxWind } from "assets/icons/wind.svg";
import { ReactComponent as WxWindGusts } from "assets/icons/windGusts.svg";
import { ReactComponent as WxFlood } from "assets/icons/wxFlood.svg";
import { ReactComponent as WxTornado } from "assets/icons/wxTornado.svg";
import { ReactComponent as WxWinter } from "assets/icons/wxWinter.svg";
import { ReactComponent as WxTropical } from "assets/icons/wxTropical.svg";
import { ReactComponent as WxOther } from "assets/icons/wxOther.svg";
import { ReactComponent as FxDroneRadar } from "assets/icons/droneRadar.svg";
import { ReactComponent as FxDrone } from "assets/icons/drone2.svg";
// import { ReactComponent as UtilityPole } from "assets/icons/utility_pole.svg";
// import { ReactComponent as UtilityPoleDouble } from "assets/icons/utility_pole_double.svg";
import mapIconTornadoAlert from "assets/icons/tile_WXTornado.svg";
import mapIconFloodAlert from "assets/icons/tile_WXFlood.svg";
import mapIconWinterAlert from "assets/icons/tile_WXWinter.svg";
import mapIconTropicalAlert from "assets/icons/tile_WXTropical.svg";
import mapIconOtherAlert from "assets/icons/tile_WXOther.svg";
import mapIconFreezing24hrs from "assets/icons/tile_freezing24hrs.svg";
import mapIconFreezingNow from "assets/icons/tile_FreezingNow.svg";
import mapIconHighTemp from "assets/icons/highTemp.svg";
import mapIconSnowLastHour from "assets/icons/tile_SnowLastHour.svg";
import mapIconSnowFuture from "assets/icons/tile_SnowFuture.svg";
import mapIconRainFuture from "assets/icons/tile_RainFuture.svg";
import mapIconWindFuture from "assets/icons/tile_WindFuture.svg";
import mapIconFreezingFuture from "assets/icons/tile_FreezingFuture.svg";
import { ReactComponent as TxStore } from "assets/icons/tx_store.svg";
import { ReactComponent as TxStadium } from "assets/icons/tx_stadium.svg";
import { ReactComponent as TxTruck } from "assets/icons/truck_solid_icon.svg";
import { ReactComponent as TxBus } from "assets/icons/bus_icon.svg";
import { ReactComponent as TxTrain } from "assets/icons/train_icon.svg";
import truckMapIcon, { ReactComponent as truckPopupIcon } from "assets/icons/truck_map.svg";
import storeMapIcon, { ReactComponent as storePopupIcon } from "assets/icons/store_map.svg";
import stadiumMapIcon, { ReactComponent as stadiumPopupIcon } from "assets/icons/stadium_map.svg";
import busMapIcon, { ReactComponent as busPopupIcon } from "assets/icons/bus_map.svg";
import trainMapIcon, { ReactComponent as trainPopupIcon } from "assets/icons/train_map.svg";
import locationMapIcon, { ReactComponent as locationPopupIcon } from "assets/icons/location_map.svg";
import alertTriangleIcon from "assets/icons/alert-triangle.svg";

/**
 * Add custom SVG icons into this array after importing them above.
 * Follow these three steps, not the casing on the icon name examples...
 * 1. Add: import { ReactComponent as IconName } from "assets/iconFile.svg";
 * 2. Add "iconName" to S4dIcons array
 * 3. Add: "iconName": props => <IconName {...props} />,
 */
export const S4dIconElements = {
  txWXAlerts: TxWXAlerts,
  txWXThresholds: TxWXThresholds,
  txTFIncidents: TxTFIncidents,
  txTFConstruction: TxTFConstruction,
  txTFCurrent: TxTFCurrent,
  txTFTraffic: TxTFTraffic,
  txWXFlood: TxWXFlood,
  txWXOther: TxWXOther,
  txWXTornado: TxWXTornado,
  txWXTropical: TxWXTropical,
  txWXWinter: TxWXWinter,
  txWXFreezing24Hrs: TxWXFreezing24Hrs,
  txFreezingNow: TxFreezingNow,
  fire: TxFireAlerts,
  txStadium: TxStadium,
  txStore: TxStore,
  txTruck: TxTruck,
  txBus: TxBus,
  txTrain: TxTrain,
  txSnowLastHour: TxSnowLastHour,
  txSnowFuture: TxSnowFuture,
  txRainFuture: TxRainFuture,
  txWindFuture: TxWindFuture,
  txFreezingFuture: TxFreezingFuture,
  wxRain: WxRain,
  wxSnow: WxSnow,
  wxTempHigh: WxTempHigh,
  wxTempLow: WxTempLow,
  wxWind: WxWind,
  wxWindGusts: WxWindGusts,
  wxFlood: WxFlood,
  wxTornado: WxTornado,
  wxWinter: WxWinter,
  wxTropical: WxTropical,
  wxOther: WxOther,
  fxDroneRadar: FxDroneRadar,
  fxDrone: FxDrone,
  //   utilityPole: UtilityPole,
  //   utilityPoleDouble: UtilityPoleDouble,
};

type S4dIconProp = keyof typeof S4dIconElements;
export type Icon = S4dIconProp;
export type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

export function S4dIcon(props: { icon: Icon }) {
  const { icon, ...rest } = props;
  return React.createElement(S4dIconElements[icon as S4dIconProp], { ...(rest as SVGProps<SVGSVGElement>) });
}

export const productIcons: Record<
  | "winter"
  | "tropical"
  | "other"
  | "freezing24"
  | "freezingNow"
  | "over30now"
  | "snow"
  | "snow12hr"
  | "rain"
  | "highWind"
  | "freezingFuture"
  | "traffic"
  | "incidents"
  | "construction"
  | "fireSat"
  | "fireNifc"
  | "FAA_NationalSecurity"
  | "FAA_Facilities"
  | "powergrid"
  | "powerlines"
  | "flood"
  | "severe"
  | "store"
  | "truck"
  | "bus"
  | "train"
  | "location"
  | "route"
  | "flightPaths"
  | "railways"
  | "maritime"
  | "stadium",
  { solidIcon: string; tileIcon?: string }
> = {
  severe: { solidIcon: "txWXTornado", tileIcon: mapIconTornadoAlert },
  flood: { solidIcon: "txWXFlood", tileIcon: mapIconFloodAlert },
  winter: { solidIcon: "txWXWinter", tileIcon: mapIconWinterAlert },
  tropical: { solidIcon: "txWXTropical", tileIcon: mapIconTropicalAlert },
  other: { solidIcon: "txWXOther", tileIcon: mapIconOtherAlert },
  freezing24: { solidIcon: "txWXFreezing24Hrs", tileIcon: mapIconFreezing24hrs },
  freezingNow: { solidIcon: "txFreezingNow", tileIcon: mapIconFreezingNow },
  over30now: { solidIcon: "wxTempHigh", tileIcon: mapIconHighTemp },
  snow: { solidIcon: "txSnowLastHour", tileIcon: mapIconSnowLastHour },
  snow12hr: { solidIcon: "txSnowFuture", tileIcon: mapIconSnowFuture },
  rain: { solidIcon: "txRainFuture", tileIcon: mapIconRainFuture },
  highWind: { solidIcon: "txWindFuture", tileIcon: mapIconWindFuture },
  freezingFuture: { solidIcon: "txFreezingFuture", tileIcon: mapIconFreezingFuture },
  traffic: { solidIcon: "txTFCurrent" },
  incidents: { solidIcon: "txTFIncidents" },
  construction: { solidIcon: "txTFConstruction" },
  fireSat: { solidIcon: "fire" },
  fireNifc: { solidIcon: "fire" },
  FAA_NationalSecurity: { solidIcon: "alert-triangle", tileIcon: alertTriangleIcon },
  FAA_Facilities: { solidIcon: "alert-triangle", tileIcon: alertTriangleIcon },
  store: { solidIcon: "txStore" },
  stadium: { solidIcon: "txStadium" },
  truck: { solidIcon: "txTruck" },
  bus: { solidIcon: "txBus" },
  train: { solidIcon: "txTrain" },
  location: { solidIcon: "map-marker-alt" },
  route: { solidIcon: "route" },
  flightPaths: { solidIcon: "fxDrone" },
  powergrid: { solidIcon: "utilityPoleDouble" },
  powerlines: { solidIcon: "utilityPole" },
  railways: { solidIcon: "route" },
  maritime: { solidIcon: "route" },
};

export const weatherThresholdIcons: Record<WeatherEventId, { popupIcon: string }> = {
  snow: { popupIcon: "txSnowFuture" },
  temperatureHigh: { popupIcon: "wxTempHigh" },
  temperatureLow: { popupIcon: "txFreezingNow" },
  heavyRain: { popupIcon: "txRainFuture" },
  highGusts: { popupIcon: "txWindFuture" },
  highWind: { popupIcon: "txWindFuture" },
};

export type CollectionIcon = "store" | "truck" | "bus" | "train" | "location" | "stadium";

export interface CollectionIconInfo {
  map: string;
  popup: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  anchor: "center" | "bottom";
}

export const CollectionIcons: Record<CollectionIcon, CollectionIconInfo> = {
  truck: { map: truckMapIcon, popup: truckPopupIcon, anchor: "center" },
  store: { map: storeMapIcon, popup: storePopupIcon, anchor: "center" },
  stadium: { map: stadiumMapIcon, popup: stadiumPopupIcon, anchor: "center" },
  bus: { map: busMapIcon, popup: busPopupIcon, anchor: "center" },
  train: { map: trainMapIcon, popup: trainPopupIcon, anchor: "center" },
  location: { map: locationMapIcon, popup: locationPopupIcon, anchor: "bottom" },
};
