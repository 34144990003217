import { BaseMapLayer, BaseMapLayerProps } from "map/services/baseMapLayer";

export type GeojsonLayerProps = BaseMapLayerProps;

export abstract class GeojsonLayer extends BaseMapLayer {
  data?: GeoJSON.FeatureCollection;

  protected abstract updateData(): Promise<void>;

  constructor(props: GeojsonLayerProps) {
    super(props);
    this.data = { features: [], id: this.id, type: "FeatureCollection" } as GeoJSON.FeatureCollection;
    this.update();
  }

  async update() {
    if (!this.map) return;
    try {
      if (this.isVisible && this.map.getStyle()) {
        this.updateSource();
        await this.updateData();
      }

      this.updateLayers();
    } catch (err) {
      if (err instanceof Error) {
        console.error("Failed to update data for layer", this.id, err.message);
      }
    }
  }

  setData(data: GeoJSON.FeatureCollection) {
    this.data = data;
    this.updateSource();
  }

  protected updateSource() {
    // protect against map crashed if the map was killed before this finished loading.
    // Have to check style due to mapbox bug
    if (!this.map?.getStyle()) return;

    const data = this.data ?? { features: [], id: this.id, type: "FeatureCollection" };

    const source = this.map.getSource(this.id) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(data);
    } else {
      this.map.addSource(this.id, { type: "geojson", data, promoteId: "id" });
    }
  }
}
