import React from "react";
import EyeOn from "assets/nav-icons/eye.svg";
import EyeOff from "assets/nav-icons/eye-off.svg";
import ExtrudeUp from "assets/icons/ExtrudeUp.svg";
import ExtrudeDown from "assets/icons/ExtrudeDown.svg";

export type VisibilityModeType = "none" | "visible" | "2d" | "3d";

type VisibilityButtonProps = {
  mode: VisibilityModeType;
  onclick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type Icons = {
  [key in VisibilityModeType]: string;
};

export const VisibilityButton = (props: VisibilityButtonProps) => {
  const { mode, onclick } = props;
  const icons: Icons = {
    none: EyeOff,
    visible: EyeOn,
    "2d": ExtrudeDown,
    "3d": ExtrudeUp,
  };
  return (
    <button onClick={onclick}>
      <img className="mr-1 h-[20px] w-[20px]" src={icons[mode]} alt="" />
    </button>
  );
};
