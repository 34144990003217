import React from "react";
import { FlightPlanJson } from "flight-plan/models";
import { DrawControl } from "map/services/drawServices";
import { MapService } from "map/services/mapService";
import { FlightBoundary } from "./flightBoundary";
import { ExtrudedPath } from "./extrudedPath";
import { FlightPin } from "./flightPin";
import { FlightUpdater } from "./flightUpdater";
import { HomePoint } from "./homePoint";
import { FlightPath } from "./flightPath";
import { ActionType } from "flight-plan/store";

export type FlightPlans = {
  flight: FlightPlanJson;
  boundary: FlightBoundary;
  pin: FlightPin;
};

export class FlightService {
  mapService: MapService;
  draw?: DrawControl;
  flightUpdater: FlightUpdater;
  flights: FlightPlans[] = [];
  active: FlightPlanJson | null = null;
  edit: FlightPlanJson | null = null;
  dispatch: React.Dispatch<ActionType>;
  path?: FlightPath;
  extrudedPath?: ExtrudedPath;
  homePoint?: HomePoint;

  constructor(mapService: MapService, dispatch: React.Dispatch<ActionType>) {
    this.mapService = mapService;
    this.draw = this.mapService.getDraw();
    this.dispatch = dispatch;
    this.flightUpdater = new FlightUpdater(this);
  }

  buildLayer(flight: FlightPlanJson) {
    const flightPlan = this.flights.find((item) => item.flight.flightPlanId === flight.flightPlanId);

    if (flightPlan && flightPlan.boundary && flightPlan.pin) {
      flightPlan.boundary.updateFlight(flight);
      flightPlan.pin.updateFlight(flight);
    } else {
      const boundary = new FlightBoundary({
        id: `${flight.flightPlanId}-boundary`,
        mapService: this.mapService,
        visible: true,
        flightPlan: flight,
      });

      const pin = new FlightPin({
        id: flight.flightPlanId,
        mapService: this.mapService,
        flightPlan: flight,
        flightService: this,
      });

      this.flights.push({ flight: flight, boundary: boundary, pin: pin });
    }
  }

  update(flight: FlightPlanJson) {
    try {
      this.buildLayer(flight);
    } catch (e) {
      console.log(e);
    }
  }

  remove(plan: FlightPlanJson) {
    try {
      const index = this.flights.findIndex((item) => item.flight.flightPlanId === plan.flightPlanId);
      if (index >= 0) {
        const flight = this.flights[index];
        flight?.boundary.remove();
        flight?.pin.remove();
        this.path?.remove();
        this.extrudedPath?.remove();
        this.homePoint?.remove();
        this.active = null;
        this.edit = null;
        this.flights = [...this.flights.slice(0, index), ...this.flights.slice(index + 1)];
      }
    } catch (e) {
      console.log(e);
    }
  }

  setVisible(id: string, visible: boolean) {
    const flight = this.flights.find((item) => item.flight.flightPlanId === id);
    flight?.boundary.setVisible(visible);
    flight?.pin.setVisible(visible);
  }

  set3d(is3d: boolean, id: string) {
    if (this.active?.flightPlanId === id) {
      this.path?.set3d(is3d);
      this.extrudedPath?.set3d(is3d);
    }
  }

  setActive(flight: FlightPlanJson | null, is3d = false) {
    if (this.active && this.edit?.flightPlanId !== this.active.flightPlanId) {
      this.setVisible(this.active.flightPlanId, true);
    }
    this.active = flight;

    this.path?.remove();
    this.extrudedPath?.remove();
    this.homePoint?.remove();

    if (this.active) {
      this.path = new FlightPath({
        id: `${this.active.flightPlanId}-path`,
        mapService: this.mapService,
        visible: !is3d,
        flightPlan: this.active,
        is3d: is3d,
      });

      this.extrudedPath = new ExtrudedPath({
        id: `${this.active.flightPlanId}-extruded`,
        mapService: this.mapService,
        visible: is3d,
        flightPlan: this.active,
        is3d: is3d,
      });

      this.homePoint = new HomePoint({
        id: `${this.active.flightPlanId}`,
        mapService: this.mapService,
        flightPlan: this.active,
        visible: true,
      });

      this.setVisible(this.active.flightPlanId, false);
    }
  }

  flyTo(id: string) {
    const flight = this.flights.find((item) => item.flight.flightPlanId === id);
    flight?.pin.flyTo();
  }
}
