import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "App";

export const appLinkToken = "airwiseapp://token/{token}";
export const appLinkLoginNeeded = "airwiseapp://userloginneeded";

const isDebug = process.env.NODE_ENV === "development";

export default function Login() {
  const { user } = useContext(AppContext);

  try {
    // get the user token from Cognito. If we were not logged in when navigating to this page Amplify would have already
    // forced an auth dialog, so we know we are always authenticated when we get here
    const currentSession = user?.cognitoUser?.getSignInUserSession();
    const cognitoToken = currentSession?.getIdToken().getJwtToken();
    if (cognitoToken) {
      // if we have a token do a window.location.href to redirect to the app deep-link URL. This way if the app is not
      // registered in the system it will do nothing.
      const redirectUrl = appLinkToken.replace("{token}", cognitoToken);
      window.location.href = redirectUrl;
    }

    // if the line above did not redirect us, the the app is not installed so just take us to the home page
    const redirectPage = isDebug ? `/token/${cognitoToken}` : "/";
    return cognitoToken ? <Navigate to={redirectPage} /> : <p>Logging in...</p>;
  } catch (error) {
    console.log("error signing in", error);
    <Navigate to={`/`} />;
  }
  return <p>Logging in...</p>;
}
