import { MapContext } from "map/Map";
import { useMemo, useContext } from "react";
import { MapService } from "./mapService";

// export type MapContextType = {
//   map?: MapService;
//   onMapMount: (map: MapService, id?: string) => void;
//   onMapUnmount: (id?: string) => void;
// };

// export const MapContext = React.createContext<MapContextType>({});

// export const MapProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
//   const [mapService, setMapService] = useState<{ map?: MapService }>({});

//   const onMapMount = useCallback((map: MapService) => {
//     setMapService({ map });
//   }, []);

//   const onMapUnmount = useCallback(() => {
//     setMapService((currMap) => {
//       if (currMap?.map) {
//         delete currMap.map;
//         return {};
//       }
//       return currMap;
//     });
//   }, []);

//   return (
//     <MapContext.Provider
//       value={{
//         map: mapService.map,
//         onMapMount,
//         onMapUnmount,
//       }}>
//       {props.children}
//     </MapContext.Provider>
//   );
// };
// export function useMap(): { current?: MapService } {
//   const currentMap = useContext(MapContext);

//   const map = useMemo(() => {
//     return currentMap?.map;
//   }, [currentMap?.map]);

//   return { current: map };
// }

export function useMap(): MapService | undefined {
  const currentMap = useContext(MapContext);

  const map = useMemo(() => {
    return currentMap;
  }, [currentMap]);

  return map;
}
