import { validateFlightPlan } from "flight-plan/helpers/flight-path";
import { FlightService } from "flight-plan/services/flightServices";
import React, { createContext } from "react";
import {
  deleteFlightPlanById,
  getFlightPlanById,
  getFlightPlans,
  postFlightPlan,
} from "../../common/adapters/flightLocationApi";
import { FlightPlanJson } from "../models";

export const FlightServiceContext = createContext<FlightService | undefined>(undefined);

export const fetchFlightsList = async () => {
  try {
    const flightPlans = await getFlightPlans();
    if (!flightPlans) return [];
    return flightPlans;
  } catch (e) {
    console.log(e);
  }
};

export const getFlightDetail = async (id: string) => {
  try {
    const res = await getFlightPlanById(id);
    const flightPlanDetail = validateFlightPlan(res);
    if (flightPlanDetail) return flightPlanDetail;
    return null;
  } catch (e) {
    console.log(e);
  }
};

export const deleteFlight = async (id: string) => {
  try {
    await deleteFlightPlanById(id);
    return id;
  } catch (e) {
    console.log(e);
  }
};

export const postFlight = async (flight: FlightPlanJson) => {
  try {
    if (flight.lastUpdated) delete flight.lastUpdated;
    await postFlightPlan(flight);
    return flight;
  } catch (e) {}
};

const getIndex = (id: string, flights: FlightPlanJson[]) => {
  return flights.findIndex((item) => item.flightPlanId === id);
};

const add = (state: FlightContextType, payload: FlightPlanJson) => {
  try {
    if (!state) return;
    const { flights } = state;
    if (getIndex(payload.flightPlanId, flights) < 0) {
      return {
        ...state,
        flights: [...flights, payload],
      };
    } else {
      update(state, payload);
    }
  } catch (e) {}
};

const update = (state: FlightContextType, payload: FlightPlanJson) => {
  const { flights } = state;
  const index = getIndex(payload.flightPlanId, flights);
  if (index >= 0) {
    return {
      ...state,
      flights: [...flights.slice(0, index), payload, ...flights.slice(index + 1)],
    };
  }
};

const remove = (state: FlightContextType, payload: string) => {
  const { flights } = state;
  const index = getIndex(payload, state.flights);
  if (index >= 0) {
    return {
      editPlan: null,
      activePlan: null,
      flights: [...flights.slice(0, index), ...flights.slice(index + 1)],
    };
  }
  return state;
};

export type ActionType = {
  type: "ADD" | "REMOVE" | "UPDATE" | "ACTIVE" | "EDIT" | "RESET";
  payload?: any;
};

export type FlightContextType = {
  flights: FlightPlanJson[];
  activeFlight: null | FlightPlanJson;
  editFlight: null | FlightPlanJson;
};

export const defaultFlightContext: FlightContextType = {
  flights: [],
  activeFlight: null,
  editFlight: null,
};

export const FlightReducer = (state: FlightContextType, action: ActionType) => {
  switch (action.type) {
    case "ADD":
      return add(state, action.payload);
      break;
    case "REMOVE":
      return remove(state, action.payload);
      break;
    case "UPDATE":
      return update(state, action.payload);
      break;
    case "ACTIVE":
      return {
        flights: state.flights,
        editFlight: null,
        activeFlight: action.payload,
      };
      break;
    case "EDIT":
      const activeFlight = action.payload
        ? null
        : state.flights.find((item) => item.flightPlanId === state.editFlight?.flightPlanId);
      return {
        flights: state.flights,
        activeFlight: activeFlight,
        editFlight: action.payload,
      };
      break;
    case "RESET":
      return defaultFlightContext;
      break;
    default:
      return action.payload;
  }
};

export const FlightsContext = createContext<{ state: FlightContextType; dispatch: React.Dispatch<ActionType> }>({
  state: defaultFlightContext,
  dispatch: () => {},
});
