import React from "react";
import { getPlanFileById } from "common/adapters/flightLocationApi";
import { FlightPlanJson } from "flight-plan/models";
import { saveAs } from "file-saver";
import DownloadIcon from "assets/nav-icons/download-cloud.svg";

export interface FlightSaveProps {
  flight: FlightPlanJson;
}
export default function FlightSave(props: FlightSaveProps) {
  const { flight } = props;

  const getPlanFile = async () => {
    getPlanFileById(flight.flightPlanId)
      .then((file) => {
        const fileName = flight.name + ".plan";
        window.location.href = "airwiseapp://flightplan/" + flight.flightPlanId;
        const fileToSave = new Blob([JSON.stringify(file)]);
        saveAs(fileToSave, fileName);
        return;
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <button onClick={getPlanFile}>
      <img className="h-[18px]" src={DownloadIcon} alt="" />
    </button>
  );
}
