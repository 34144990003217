import MapboxDraw, { DrawEvent } from "@mapbox/mapbox-gl-draw";
import { MapboxMap } from "models";
import { MapService } from "./mapService";

export type DrawEventType =
  | "draw.create"
  | "draw.delete"
  | "draw.update"
  | "draw.render"
  | "draw.combine"
  | "draw.uncombine"
  | "draw.modechange"
  | "draw.actionable"
  | "draw.selectionchange";

export type SelectionType =
  | "draw_line_string"
  | "draw_polygon"
  | "draw_point"
  | "simple_select"
  | "direct_select"
  | "static";

export type DrawModes = "draw_polygon" | "draw_point" | "simple_select";
export type ControlType = "point" | "line_string" | "polygon" | "trash" | "combine_features" | "uncombine_features";
export const defaultMode: SelectionType = "simple_select";
export type DrawEventRecordType = {
  type: DrawEventType;
  callback: (e: DrawEvent | any) => void;
};

const drawOptions = {
  displayControlsDefault: false,
  // controls: { point: true, polygon: true, trash: true },
  controls: { point: true, polygon: true, trash: true },
  styles: [
    // ACTIVE (being drawn)
    // line stroke
    {
      id: "gl-draw-line",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#D20C0C",
        "line-dasharray": [0.2, 2],
        "line-width": 2,
      },
    },
    // polygon fill
    {
      id: "gl-draw-polygon-fill",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
      paint: {
        "fill-color": "#D20C0C",
        "fill-outline-color": "#D20C0C",
        "fill-opacity": 0.1,
      },
    },
    // polygon mid points
    {
      id: "gl-draw-polygon-midpoint",
      type: "circle",
      filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
      paint: {
        "circle-radius": 7,
        "circle-color": "#f26948",
        "circle-stroke-color": "#fff",
        "circle-stroke-width": 2,
      },
      // polygon outline stroke
      // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    },
    {
      id: "gl-draw-polygon-stroke-active",
      type: "line",
      filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#D20C0C",
        "line-dasharray": [0.2, 2],
        "line-width": 2,
      },
    },
    // vertex point halos
    {
      id: "gl-draw-polygon-and-line-vertex-halo-active",
      type: "circle",
      filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
      paint: {
        "circle-radius": 10,
        "circle-color": "#FFF",
      },
    },
    // vertex points
    {
      id: "gl-draw-polygon-and-line-vertex-active",
      type: "circle",
      filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
      paint: {
        "circle-radius": 8,
        "circle-color": "#D20C0C",
      },
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
      id: "gl-draw-line-static",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    },
    // polygon fill
    {
      id: "gl-draw-polygon-fill-static",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
      paint: {
        "fill-color": "#000",
        "fill-outline-color": "#000",
        "fill-opacity": 0.1,
      },
    },
    // polygon outline
    {
      id: "gl-draw-polygon-stroke-static",
      type: "line",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    },
  ],
};

export class DrawControl {
  mapService: MapService;
  draw: MapboxDraw;
  editingFeature = false;
  mode: SelectionType;
  map?: MapboxMap | null;
  listeners: DrawEventRecordType[] = [];
  constructor(map: MapService) {
    this.mapService = map;
    this.draw = new MapboxDraw(drawOptions);
    this.mode = defaultMode;
    this.map = this.mapService.getMap();
  }

  add() {
    if (this.map?.hasControl(this.draw)) return;
    this.mapService.addControl(this.draw, "top-right");
    this.setMode(defaultMode);
  }

  remove() {
    this.mapService.removeControl(this.draw);
  }

  toggle() {
    const els = document.getElementsByClassName("mapbox-gl-draw_ctrl-draw-btn");
    const parent = els.length ? els[0].parentElement : null;
    if (!this.editingFeature && this.mode === defaultMode) return parent?.classList.add("hidden");
    parent?.classList.remove("hidden");
  }

  setMode(mode: any) {
    this.mode = mode;
    this.draw.changeMode(mode);
    this.toggle();
  }

  addFeature(feature: GeoJSON.Feature | GeoJSON.FeatureCollection, listener?: (e: Object | undefined) => void) {
    this.editingFeature = true;
    if (this.draw) this.draw.add(feature);
    this.toggle();
  }

  removeFeature(featureId?: string) {
    this.editingFeature = false;
    if (featureId) return this.draw.delete(featureId);
    this.draw.deleteAll();
    this.toggle();
  }

  on(type: DrawEventType, listener: (e: DrawEvent | any) => void) {
    if (!this.listeners.find((l) => l.type === type && l.callback === listener)) {
      this.listeners.push({
        type: type,
        callback: listener,
      });
      this.mapService.eventHandler?.on(type, null, listener, true);
    }
  }

  off(type?: DrawEventType, listener?: (ev: DrawEvent | any) => void) {
    if (type && listener) {
      const index = this.listeners.findIndex((l) => l.type === type && l.callback === listener);
      if (index >= 0) {
        this.mapService.eventHandler?.off(type);
        return this.listeners.splice(index, 1);
      }
    } else {
      this.listeners.forEach((l) => {
        this.mapService.eventHandler?.off(l.type);
      });
      this.listeners = [];
    }
  }
}
