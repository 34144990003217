import React, { useContext, useState } from "react";
import { FlightPlanJson } from "../models";
import { defaultFlightMetadata, FlightPlanGenerator } from "../helpers/flight-path";
import type { Feature, Polygon } from "@turf/turf";
import { postFlight, FlightsContext } from "../store";
import { calculateFootprint, generateLinePath, getCentroid } from "flight-plan/helpers";
import { generateUuid } from "common/utils/generateUuid";
import { useMap } from "map/services/useMap";

export default function DrawPath({
  drawing,
  setDrawing,
}: {
  drawing: boolean;
  setDrawing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const map = useMap();
  const draw = map?.getDraw();
  const { dispatch } = useContext(FlightsContext);
  const [flightPlanJson, setFlightPlanJson] = useState<FlightPlanJson | null>(null);

  const drawCustom = (): void => {
    if (!draw) return;
    setDrawing(true);
    draw.add();
    draw?.setMode("draw_polygon");
    draw.on("draw.create", onDrawCreate);
  };

  const onDrawCreate = (e: any) => {
    initPath(e);
  };

  const initPath = async (e: any) => {
    const boundary = e.features[0] as Feature<Polygon>;
    const geometry = boundary.geometry;
    const flightMetadata = defaultFlightMetadata;
    const { adjustedFootprintFront, adjustedFootprintSide } = calculateFootprint(flightMetadata);
    flightMetadata.cameraMetadata.adjustedFootprintFrontal = adjustedFootprintFront;
    flightMetadata.cameraMetadata.adjustedFootprintSide = adjustedFootprintSide;
    const lineStringFeatureCollection = generateLinePath(flightMetadata, boundary);
    const center = getCentroid(boundary).geometry.coordinates;
    const flightPlan = FlightPlanGenerator(
      generateUuid(),
      "",
      flightMetadata,
      geometry,
      lineStringFeatureCollection.geometry,
      center,
    );
    flightPlan.lastUpdated = new Date();
    setFlightPlanJson(flightPlan);
  };

  const cancelDraw = (): void => {
    setDrawing(false);
    setFlightPlanJson(null);
    draw?.setMode("simple_select");
    draw?.removeFeature();
    draw?.off();
    draw?.remove();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!flightPlanJson) return;
    setFlightPlanJson({
      ...flightPlanJson,
      name: e.target.value.replace(/[/|\\:*?"<>]/g, "-"),
    });
  };

  const onSave = async () => {
    if (flightPlanJson && flightPlanJson.name.length > 0) {
      await postFlight(flightPlanJson);
      dispatch({
        type: "ADD",
        payload: flightPlanJson,
      });
      cancelDraw();
    }
  };

  return (
    <React.Fragment>
      {drawing ? (
        <React.Fragment>
          <p className="mb-2 grow border-b px-2 text-center font-medium">Draw Path</p>
          {!flightPlanJson && (
            <React.Fragment>
              <p className="mb-2 grow px-2 text-sm">Click the map to begin drawing a polygon.</p>
              <p className="mb-2 grow border-b px-2 pb-2 text-sm">
                Double-click when you draw the final vertex to complete the polygon.
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <button className="btn w-full" onClick={drawCustom}>
          Draw
        </button>
      )}

      {drawing && (
        <React.Fragment>
          {flightPlanJson && (
            <React.Fragment>
              <p className="mb-2 grow px-2">Give your flight a name to save it:</p>
              <input
                type="text"
                placeholder="flight name"
                className="input input-bordered mb-1 w-full"
                value={flightPlanJson.name}
                onChange={onInputChange}
              />
              <button className="btn mb-1 w-full" onClick={onSave} disabled={!flightPlanJson.name.length}>
                Save
              </button>
            </React.Fragment>
          )}
          <button className="btn w-full" onClick={cancelDraw}>
            Cancel
          </button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
