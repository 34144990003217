import React, { useContext, useMemo } from "react";
import { FlightsContext } from "../store";
import FlightSettings from "../edit/FlightSettings";
import FlightInfo from "flight-plan/list/Info";
import Delete from "flight-plan/components/Delete";

export default function EditFlight() {
  const { state, dispatch } = useContext(FlightsContext);

  const editFlight = useMemo(() => {
    if (state) {
      return state.editFlight;
    }
    return null;
  }, [state]);

  const onCancelClick = () => {
    dispatch({
      type: "EDIT",
      payload: null,
    });
  };

  return (
    <React.Fragment>
      {editFlight && (
        <React.Fragment>
          <div className="w-[310px] rounded-lg bg-white">
            <div className="border-b p-2">
              <p className="font-medium">Edit {editFlight.name}</p>
            </div>
            <div className="p-2">
              <FlightSettings />
              <div className="divider"></div>
              <div className="flex gap-1">
                <Delete flightPlan={editFlight} />
                <button className="btn btn-sm grow" onClick={onCancelClick}>
                  Close
                </button>
              </div>
            </div>
          </div>

          <div className="mt-2 w-[310px] rounded-lg bg-white">
            <div className="p-2">
              <FlightInfo flightPlanJson={editFlight} showVideoCapable={false} showAlerts={false} />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
