/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FlightsContext, FlightServiceContext } from "../store";
import structuredClone from "@ungap/structured-clone";
import FlightInfo from "./Info";
import { ReactComponent as ExtrudeUp } from "assets/icons/ExtrudeUp.svg";
import { ReactComponent as ExtrudeDown } from "assets/icons/ExtrudeDown.svg";
import Delete from "flight-plan/components/Delete";

export default function ActiveFlight() {
  const { state, dispatch } = useContext(FlightsContext);
  const flightService = useContext(FlightServiceContext);
  const [in3D, setIn3D] = useState(false);

  const activeFlight = useMemo(() => {
    if (state) return state.activeFlight;
    return null;
  }, [state]);

  useEffect(() => {
    if (activeFlight) return flightService?.setActive(activeFlight);
    flightService?.setActive(null);
  }, [activeFlight]);

  const onEditClick = () => {
    const flightPlan = structuredClone(state.activeFlight);
    if (!flightPlan) return;
    dispatch({
      type: "EDIT",
      payload: flightPlan,
    });
  };

  const onCancelClick = () => {
    dispatch({
      type: "ACTIVE",
      payload: null,
    });
  };

  const on3DClick = () => {
    if (activeFlight) {
      flightService?.set3d(!in3D, activeFlight.flightPlanId);
      setIn3D(!in3D);
    }
  };

  return (
    <React.Fragment>
      {activeFlight && (
        <div className="mt-2 w-[310px] rounded-lg bg-white">
          <div className="flex items-center justify-between border-b p-2">
            <p className="font-medium">{activeFlight.name}</p>
            <button className="btn btn-outline btn-sm hover:bg-base-200" onClick={on3DClick}>
              {in3D ? <ExtrudeDown /> : <ExtrudeUp />}
            </button>
          </div>
          <div className="p-2">
            <FlightInfo flightPlanJson={activeFlight} showVideoCapable={true} showAlerts={true} />
            <div className="divider"></div>
            <div className="flex gap-1">
              <Delete flightPlan={activeFlight} />
              <button className="btn btn-sm grow" onClick={onEditClick}>
                Edit
              </button>
              <button className="btn btn-secondary btn-sm grow" onClick={onCancelClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
