export function colorToHex(color: string) {
  // we don't know what color type this is, so easiest way to normalize it is
  // to assign it to an element then ask for it back.
  const d = document.createElement("div");
  d.style.backgroundColor = color;
  return RGBToHex(d.style.backgroundColor);
}

export function RGBToHex(rgb: string) {
  // Choose correct separator
  const sep = rgb.indexOf(",") > -1 ? "," : " ";
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbArr = rgb.substr(4).split(")")[0].split(sep);

  let r = (+rgbArr[0]).toString(16),
    g = (+rgbArr[1]).toString(16),
    b = (+rgbArr[2]).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}

export function LightenDarkenColor(col: string, amt: number) {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}
