import React, { ChangeEvent, useEffect, useState } from "react";
import { BaseMapLayer } from "map/services/baseMapLayer";
import { VisibilityButton, VisibilityModeType } from "./VisibilityButton";

export type LayerComponentProps<T> = {
  name: string;
  layers?: T[];
  fill?: string;
  opacity?: number;
  visibility: VisibilityModeType;
  visibilityModes: VisibilityModeType[];
  children?: React.ReactNode;
};

export function MapOverlay<T extends BaseMapLayer>(props: LayerComponentProps<T>) {
  const { name, visibility, visibilityModes } = props;
  const [opacity, setOpacity] = useState(props.opacity);
  const [layers, setLayers] = useState<T[]>(props.layers ?? []);
  const [visibilityMode, setVisibilityMode] = useState<VisibilityModeType>(visibility);

  useEffect(() => {
    if (props.layers) {
      setLayers(props.layers);
      props.layers.forEach((l) => {
        l.setVisible(visibilityMode !== "none");
        l.set3dMode(visibilityMode === "3d");
      });
    }
    return () => {
      layers?.forEach((l) => l.setVisible(false));
      setLayers([]);
    };
  }, [layers, props.layers, visibilityMode]);

  const onSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    setOpacity(value);
    layers?.forEach((layer) => {
      layer.setLayerOpacity(value);
    });
  };

  const onVisibilityChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = visibilityModes.indexOf(visibilityMode);
    const mode = visibilityModes[(index + 1) % visibilityModes.length];
    setVisibilityMode(mode);
  };

  return (
    <div className="border-b px-2 py-2 last-of-type:border-0">
      <label className={`flex w-full cursor-pointer items-center ${props.opacity === undefined ? "" : ""} `}>
        <VisibilityButton mode={visibilityMode} onclick={onVisibilityChange} />
        {name}
      </label>
      {props.opacity !== undefined && (
        <div className="mt-1 flex w-full items-center">
          <input
            type="range"
            min="0"
            max="1"
            step={0.01}
            value={opacity}
            className="range range-xs mr-2"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            style={{ "--range-shdw": visibilityMode === "none" ? "var(--b2)" : "var(--bc)" }}
            onChange={onSliderChange}
            disabled={visibilityMode === "none"}
          />
          <span>{Math.round((opacity ?? 0) * 100)}%</span>
        </div>
      )}
    </div>
  );
}
