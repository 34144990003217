import React from "react";
import Buildings from "./Buildings";
import SubstationLayer from "./Substation";
import PowerLinesLayer from "./PowerLines";
import FlightRestrictionLayer from "./FlightRestriction";
import Terrain from "./Terrain";
import WeatherAlertLayer from "./weatherAlerts";
import LayerIcon from "assets/nav-icons/layers.svg";

export default function Layers() {
  return (
    <div className="w-[220px] rounded-lg bg-white">
      <div className="flex w-full border-b p-2">
        <img src={LayerIcon} alt="Layer icon" className="mr-2 h-[22px]" />
        <p className="grow font-medium">Map Layers</p>
      </div>
      <Terrain />
      <Buildings />
      <PowerLinesLayer />
      <SubstationLayer />
      <FlightRestrictionLayer />
      <WeatherAlertLayer />
    </div>
  );
}
