import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// note: mapbox css must be loaded AFTER amplify/ui-react
import "@aws-amplify/ui-react/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import Map from "./map/Map";
import "App.css";
import User from "authenticator/models/user";
import amplifyConfig from "amplifyConfig";
import { isAW } from "authenticator/authenticatorServices";
import { Authenticator, AuthenticatorProvider, useAuthenticator } from "authenticator/Authenticator";
import RefreshToken from "authenticator/RefreshToken";
import Logout from "authenticator/Logout";
import FlightsPage from "flight-plan/FlightsPage";
import { QueryClientProvider } from "react-query";
import { queryClient } from "common/query/query";
import Login from "authenticator/Login";
import Token from "authenticator/Token";
import ErrorDialog from "common/components/ErrorDialog";
import { VersionChecker } from "common/components/VersionChecker";

const appConfig = { ...amplifyConfig.AppConfig, icon: isAW ? "aw" : "s4d" };
export type AppContextType = {
  appConfig: {
    icon: string;
    name: string;
    mapStyle?: string;
  };
  user: User;
  setUser?: React.Dispatch<React.SetStateAction<User | undefined>>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
};
export const AppContext = React.createContext<AppContextType>({ appConfig } as AppContextType);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatorProvider>
        <div className="App h-full">
          <AuthOrMain />
        </div>
      </AuthenticatorProvider>
    </QueryClientProvider>
  );
}

const AuthOrMain = () => {
  const { route, user: amplifyUser } = useAuthenticator((context) => [context.route]);
  const [user, setUser] = useState<User | undefined>(new User(amplifyUser));
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    amplifyUser && setUser(new User(amplifyUser));
  }, [amplifyUser]);

  return route === "authenticated" && user && amplifyUser ? (
    <AppContext.Provider value={{ appConfig, user, setUser, error, setError }}>
      <ErrorDialog />
      <Router>
        <VersionChecker>
          <Routes>
            <Route path="" element={<Map />} />
            <Route path="/login" element={<Login />} />
            <Route path="/refreshtoken" element={<RefreshToken />} />
            <Route path="/token/:token" element={<Token />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/flights" element={<FlightsPage />} />
          </Routes>
        </VersionChecker>
      </Router>
    </AppContext.Provider>
  ) : (
    <Router>
      <Routes>
        <Route path="/refreshtoken" element={<RefreshToken />} />
        <Route path="/terms">Terms</Route>
        <Route path="*" element={<Authenticator />} />
      </Routes>
    </Router>
  );
};

export default App;
