/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FlightPlanJson } from "../models";
import FlightItem from "./FlightItem";
import { fetchFlightsList, FlightsContext, FlightServiceContext, getFlightDetail } from "../store";
import DroneIcon from "assets/icons/drone2.svg";
import MaximizeIcon from "assets/nav-icons/maximize.svg";
import ArrowDown from "assets/nav-icons/arrow-down.svg";

type FilterByType = "date" | "alphabetic";
export type FlightFilterType = {
  by: FilterByType;
  descending: boolean;
  keyword?: string;
};

export default function FlightsList() {
  const { state, dispatch } = useContext(FlightsContext);
  const flightService = useContext(FlightServiceContext);
  const [isMax, setMax] = useState(false);
  const inEdit = useRef<null | string>(null);
  const [filter, setFilter] = useState<FlightFilterType>({ by: "alphabetic", descending: true });

  const editFlight = useMemo(() => {
    if (state) return state.editFlight;
    return null;
  }, [state]);

  const flights = useMemo(() => {
    if (state) {
      return state.flights.sort((a, b) => {
        if (filter.by === "date" && a.lastUpdated && b.lastUpdated) {
          const dateA = new Date(a.lastUpdated).getTime();
          const dateB = new Date(b.lastUpdated).getTime();
          return filter.descending ? dateB - dateA : dateA - dateB;
        }
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA > nameB && filter.descending ? 1 : -1;
      });
    }
    return [];
  }, [state, filter]);

  const onFilterToggle = (by: FilterByType) => {
    const descending = filter.by === by ? !filter.descending : true;
    setFilter({ by: by, descending: descending });
  };

  const onMaxToggle = () => {
    setMax(!isMax);
  };

  useEffect(() => {
    getFlightsList();
    return () => {
      dispatch({
        type: "RESET",
      });
    };
  }, []);

  const getFlightsList = async () => {
    const flightsList = await fetchFlightsList();
    flightsList?.forEach(async (flight) => {
      const detail = await getFlightDetail(flight.flightPlanId);
      if (detail) {
        detail.lastUpdated = flight.lastUpdated;
        dispatch({
          type: "ADD",
          payload: detail,
        });
      }
    });
  };

  useEffect(() => {
    if (editFlight && !inEdit.current) {
      inEdit.current = editFlight.flightPlanId;
      flightService?.flightUpdater.addPlan(editFlight);
      flightService?.setVisible(editFlight.flightPlanId, false);
    }

    if (inEdit.current && !editFlight) {
      flightService?.flightUpdater.remove();
      flightService?.setVisible(inEdit.current, true);
      inEdit.current = null;
    }
  }, [editFlight]);

  const metadata = useMemo(() => {
    if (editFlight) return editFlight.flightMetadata;
    return null;
  }, [editFlight]);

  useEffect(() => {
    if (metadata && inEdit.current) flightService?.flightUpdater.onMetadataUpdate(metadata);
  }, [metadata]);

  return (
    <React.Fragment>
      {flights && flightService && (
        <div
          className={`overflow-hidden rounded-lg bg-white ${
            editFlight ? "h-0 w-0" : isMax ? "h-[calc(100vh_-_2rem)] w-[calc(100vw_-_78px)] shadow-md" : "w-[310px]"
          }`}>
          <div className="flex w-full items-center border-b p-2">
            <img src={DroneIcon} alt="drone icon" className="mr-1 h-[22px]" />
            <p className="grow font-medium">Flights List</p>
            <div className="btn-group mx-1 w-full max-w-[150px]">
              <button
                className={`btn btn-sm flex-1 gap-0 border-0 px-1 text-black  hover:bg-slate-400 ${
                  filter.by === "alphabetic" ? "bg-slate-300" : "bg-slate-200"
                }`}
                onClick={() => onFilterToggle("alphabetic")}>
                A-Z
                {filter.by === "alphabetic" && (
                  <img src={ArrowDown} alt="" className={`${filter.descending ? "rotate-180" : ""}`} />
                )}
              </button>
              <button
                className={`btn btn-sm flex-1 gap-0 border-0 px-1 text-black hover:bg-slate-400 ${
                  filter.by === "date" ? "bg-slate-300" : "bg-slate-200"
                }`}
                onClick={() => onFilterToggle("date")}>
                Date
                {filter.by === "date" && (
                  <img src={ArrowDown} alt="" className={`${filter.descending ? "rotate-180" : ""}`} />
                )}
              </button>
            </div>
            <button onClick={onMaxToggle}>
              <img src={MaximizeIcon} alt="maximize" className="h-[22px] cursor-pointer" />
            </button>
          </div>
          <div className={`${isMax ? "max-h-[calc(100vh_-_50px)]" : "max-h-[300px]"} overflow-y-auto`}>
            {flights.map((flight: FlightPlanJson) => (
              <FlightItem
                key={flight.flightPlanId}
                id={flight.flightPlanId}
                flight={flight}
                isMax={isMax}
                closeMax={onMaxToggle}
              />
            ))}
          </div>
          <div
            className={`pointer-events-none fixed top-0 left-0 -z-10 h-[100vh] w-full touch-none bg-slate-600 opacity-50 ${
              !isMax ? "hidden" : ""
            }`}></div>
        </div>
      )}
    </React.Fragment>
  );
}
