import React, { useMemo } from "react";
import type { FillLayer, LineLayer } from "mapbox-gl";
import { GeojsonLayer } from "map/services/geojsonLayer";
import { fetchWeatherAlerts } from "common/adapters/weatherApi";
import WeatherAlertPopup from "map/popups/WeatherAlertPopup";
import mapboxgl from "mapbox-gl";
import { WeatherAlertWithoutPolygon } from "models/weatherAlert";
import { MapOverlay } from "map/components/MapOverlay";
import { useMap } from "map/services/useMap";

const id = "weather-alerts";
const opacity = 0.7;
const visible = true;

export class WeatherAlertsMapLayer extends GeojsonLayer {
  opacity = 1;

  protected async updateData() {
    try {
      const data = await fetchWeatherAlerts();
      if (data && data.features.length) this.data = data;
      this.updateSource();
    } catch (e) {}
  }

  buildLayers() {
    return [
      {
        minzoom: 4,
        maxzoom: 22,
        id: `${id}-fill`,
        source: id,
        type: "fill",
        layout: {
          visibility: visible ? "visible" : "none",
        },
        paint: {
          "fill-opacity-transition": { delay: 0, duration: 0 },
          "fill-color-transition": { delay: 0, duration: 0 },
          "fill-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            ["case", ["boolean", ["feature-state", "hover"], false], opacity * 1.25, opacity],
            12,
            ["case", ["boolean", ["feature-state", "hover"], false], opacity * 1.25, opacity],
            16,
            ["case", ["boolean", ["feature-state", "hover"], false], opacity * 0.35, opacity * 0.25],
          ],
          "fill-color": ["get", "color"],
          "fill-antialias": false,
        },
      } as FillLayer,
      {
        minzoom: 4,
        maxzoom: 22,
        id: `${id}-line`,
        source: id,
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: visible ? "visible" : "none",
        },
        paint: {
          "line-opacity": 1, //0.6,
          "line-color": ["get", "color"],
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            ["case", ["boolean", ["feature-state", "hover"], false], 4, 2],
            10,
            ["case", ["boolean", ["feature-state", "hover"], false], 8, 2],
            16,
            ["case", ["boolean", ["feature-state", "hover"], false], 10, 2],
          ],
        },
      } as LineLayer,
    ];
  }

  setVisible(visible: boolean, timeIndex?: number) {
    super.setVisible(visible, this.timeIndex);
    if (!this.layers.length) return;
    const id = this.layers[0].id;
    if (this.isVisible) {
      this.on("click", id, this.onClick);
    } else {
      this.off("click", id, this.onClick);
    }
  }

  onClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const features = e.features?.filter((f) => f.source === id);
    if (!features?.length) return;
    const f = features[0];
    if (!f.properties) return;
    const el = (
      <WeatherAlertPopup
        key={this.id}
        id={this.id}
        product={f.properties.weatherAlertType}
        alertProps={f.properties as WeatherAlertWithoutPolygon}
        timeIndex={this.timeIndex}
      />
    );
    this.popup?.add({
      coordinates: [e.lngLat.lng, e.lngLat.lat],
      content: el,
    });
  };
}

export default function WeatherAlertLayer() {
  const mapService = useMap();
  const name = "Weather Alert";

  const weatherMapLayers = useMemo(
    () => (mapService ? [new WeatherAlertsMapLayer({ id: "weather-alerts", mapService: mapService })] : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay
        layers={weatherMapLayers}
        opacity={0.7}
        visibility={"visible"}
        visibilityModes={["none", "visible"]}
        name={name}
      />
    </React.Fragment>
  );
}
