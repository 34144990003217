/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MessageDialog from "./MessageDialog";

export function VersionChecker(props: React.PropsWithChildren<{}>) {
  const [currentAssetFilename, setCurrentAssetFilename] = useState<string>("");
  const [newVersionDetected, setNewVersionDetected] = useState(false);
  const location = useLocation();

  useEffect(() => {
    checkForNewVersion();
    return () => setNewVersionDetected(false);
  }, [location]);

  const onConfirm = () => {
    setNewVersionDetected(false);
    window.location.reload();
  };

  const checkForNewVersion = async () => {
    try {
      const response = await fetch(`${window.location.origin}/asset-manifest.json`);

      if (!response.ok) {
        const errorMessage = await response.json();
        console.error(`Error fetching asset manifest:`, errorMessage);
        throw Error(errorMessage);
      }

      const responseBody = await response.json();
      const fetchedAssetFilename = responseBody.files["main.js"];

      if (currentAssetFilename.length && currentAssetFilename !== fetchedAssetFilename) {
        setNewVersionDetected(true);
      }

      setCurrentAssetFilename(fetchedAssetFilename);
    } catch (error) {
      console.error("Error checking app version via chunk hash:", error);
    }
  };

  return (
    <React.Fragment>
      {props.children as React.ReactElement}
      <MessageDialog
        title="New Application Version"
        message="This application has been updated, please refresh your browser to apply the update."
        show={newVersionDetected}
        onConfirm={onConfirm}
      />
    </React.Fragment>
  );
}
