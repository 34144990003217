import { calculateFootprint, generateLinePath } from "flight-plan/helpers";
import { FlightMetadata, FlightPlanJson } from "flight-plan/models";
import { FlightService } from "./flightServices";
import type { Feature, Geometry, LineString, Polygon, Position } from "@turf/turf";
import { DrawControl } from "map/services/drawServices";
import { FlightPlanGenerator, getHomePoint, getSurveyBoundary } from "flight-plan/helpers/flight-path";
import { FlightPath } from "./flightPath";
import { postFlightPlan } from "common/adapters/flightLocationApi";
import { HomePoint } from "./homePoint";

export class FlightUpdater {
  flightService: FlightService;
  draw?: DrawControl;
  metadata?: FlightMetadata;
  flightPlan?: FlightPlanJson;
  homePoint?: Position;
  boundary?: Feature<Polygon>;
  path?: Feature<LineString>;
  pathLayer?: FlightPath;
  homePointLayer?: HomePoint;

  constructor(flightService: FlightService) {
    this.flightService = flightService;
    this.draw = flightService.mapService.getDraw();
  }

  addPlan(flightPlan: FlightPlanJson) {
    this.flightService.edit = flightPlan;
    this.flightPlan = flightPlan;
    this.metadata = this.flightPlan.flightMetadata;
    this.boundary = getSurveyBoundary(this.flightPlan);
    this.homePoint = getHomePoint(this.flightPlan);
    this.generateSurveyPath();
    this.addToDraw();
    this.buildLayer();
  }

  buildLayer() {
    if (this.flightPlan) {
      if (this.pathLayer) this.pathLayer.remove();
      this.pathLayer = new FlightPath({
        mapService: this.flightService.mapService,
        id: this.flightPlan.flightPlanId + "-edit",
        flightPlan: this.flightPlan,
        visible: true,
        is3d: false,
      });

      if (this.homePointLayer) this.homePointLayer.remove();
      this.homePointLayer = new HomePoint({
        id: this.flightPlan.flightPlanId,
        flightPlan: this.flightPlan,
        mapService: this.flightService.mapService,
        editable: true,
        visible: true,
        callback: this.onHomePointUpdate,
      });
    }
  }

  async generateSurveyPath() {
    if (this.metadata && this.boundary) {
      const { defaultAltitude, cameraMetadata } = this.metadata;
      let { adjustedFootprintFrontal, adjustedFootprintSide } = cameraMetadata;

      if (defaultAltitude > 0 && adjustedFootprintFrontal <= 0 && adjustedFootprintSide <= 0) {
        const footprint = calculateFootprint(this.metadata);
        adjustedFootprintFrontal = footprint.adjustedFootprintFront;
        adjustedFootprintSide = footprint.adjustedFootprintSide;
      }

      if (this.boundary) {
        try {
          const path = generateLinePath(this.metadata, this.boundary);
          if (!path) return null;
          this.path = path;
          this.updateFlightPlan();
        } catch (err) {
          console.log(err);
          return null;
        }
      }
    }
  }

  addToDraw() {
    if (this.boundary) {
      this.draw?.add();
      this.draw?.addFeature(this.boundary);
      this.draw?.on("draw.update", this.onDrawUpdate);
    }
  }

  onDrawUpdate = (e: any) => {
    this.boundary = e.features[0];
    this.generateSurveyPath();
  };

  onMetadataUpdate(metadata: FlightMetadata) {
    this.metadata = metadata;
    this.generateSurveyPath();
  }

  onHomePointUpdate = (coord: number[]) => {
    this.homePoint = coord;
    this.generateSurveyPath();
  };

  async updateFlightPlan() {
    if (this.flightPlan && this.metadata && this.boundary && this.homePoint && this.path) {
      const updatedFlight = FlightPlanGenerator(
        this.flightPlan.flightPlanId,
        this.flightPlan.name,
        this.metadata,
        this.boundary.geometry,
        this.path.geometry as Geometry,
        this.homePoint,
      ) as FlightPlanJson;
      this.pathLayer?.updateFlight(updatedFlight);
      await postFlightPlan(updatedFlight);
      this.flightService.dispatch({
        type: "UPDATE",
        payload: updatedFlight,
      });
    }
  }

  remove() {
    this.flightService.edit = null;
    this.draw?.setMode("simple_select");
    this.draw?.removeFeature();
    this.draw?.off();
    this.draw?.remove();
    this.pathLayer?.remove();
    this.homePointLayer?.remove();
  }
}
