import React, { useState } from "react";
import UploadPath from "./UploadPath";
import DrawPath from "./DrawPath";
import CreateIcon from "assets/nav-icons/plus.svg";

export default function CreatePath() {
  const [drawing, setDrawing] = useState(false);

  return (
    <React.Fragment>
      <div className="min-w-[200px] rounded-xl bg-white">
        <div className="flex w-full items-end border-b p-2">
          <img src={CreateIcon} alt="create icon" className="mr-2 h-[22px]" />
          <p className="grow font-medium">Create Flight</p>
        </div>
        <div className="p-2">
          {!drawing && (
            <React.Fragment>
              <UploadPath />
              <div className="divider">OR</div>
            </React.Fragment>
          )}
          <DrawPath drawing={drawing} setDrawing={setDrawing} />
        </div>
      </div>
    </React.Fragment>
  );
}
