/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import structuredClone from "@ungap/structured-clone"; //cspell:words ungap
import { FlightMetadata } from "../models";
import { calculateFootprint } from "../helpers";

import NavigationIcon from "assets/nav-icons/navigation.svg";
import CameraIcon from "assets/nav-icons/camera.svg";
import SendIcon from "assets/nav-icons/send.svg";
import Icon from "../../icons/Icon";
// import HomePointSettings from "./home-point-settings";

export default function FlightSettingsForm({
  flightMetadata,
  updateMetadata,
}: {
  flightMetadata: FlightMetadata;
  updateMetadata: Function;
}) {
  const [activeMenu, setActiveMenu] = useState<"navigation" | "camera" | "drone">("navigation");

  useEffect(() => {
    if (
      flightMetadata.defaultAltitude > 0 &&
      flightMetadata.cameraMetadata.adjustedFootprintFrontal <= 0 &&
      flightMetadata.cameraMetadata.adjustedFootprintSide <= 0
    ) {
      const settings = updateAdjustedFootprint({ ...structuredClone(flightMetadata) });
      updateActiveLayer(settings);
    }
  }, [flightMetadata]);

  const onValueChange = (value: number | string | boolean, name: string, group?: keyof FlightMetadata) => {
    if (!flightMetadata) return;
    let settings: FlightMetadata = structuredClone(flightMetadata);
    if (group) {
      settings = {
        ...settings,
        [group]: {
          ...(settings[group] as Object),
          [name]: value,
        },
      };
    } else {
      settings = {
        ...settings,
        [name]: value,
      };
    }

    updateActiveLayer(updateAdjustedFootprint({ ...settings }));
  };

  const updateActiveLayer = (settings: FlightMetadata) => {
    updateMetadata(settings);
  };

  const updateAdjustedFootprint = (settings: FlightMetadata): FlightMetadata => {
    const { adjustedFootprintFront, adjustedFootprintSide } = calculateFootprint(settings);

    return {
      ...settings,
      cameraMetadata: {
        ...settings.cameraMetadata,
        adjustedFootprintFrontal: adjustedFootprintFront,
        adjustedFootprintSide: adjustedFootprintSide,
      },
    };
  };

  return (
    <React.Fragment>
      {flightMetadata && (
        <React.Fragment>
          <div className="btn-group w-full">
            <button
              className={`btn btn-sm grow border-gray-300 bg-gray-300 ${
                activeMenu === "navigation" ? "bg-gray-400" : ""
              }`}
              onClick={() => setActiveMenu("navigation")}>
              <Icon source={NavigationIcon} size={12} />
            </button>
            <button
              className={`btn btn-sm grow border-gray-300 bg-gray-300 ${activeMenu === "camera" ? "bg-gray-400" : ""}`}
              onClick={() => setActiveMenu("camera")}>
              <Icon source={CameraIcon} size={12} />
            </button>
            <button
              className={`btn btn-sm grow border-gray-300 bg-gray-300 ${activeMenu === "drone" ? "bg-gray-400" : ""}`}
              onClick={() => setActiveMenu("drone")}>
              <Icon source={SendIcon} size={12} />
            </button>
          </div>

          <div>
            <div>
              {activeMenu === "navigation" && (
                <form>
                  <InputField
                    key={"defaultAltitude"}
                    name="defaultAltitude"
                    type="number"
                    label="Altitude"
                    value={flightMetadata.defaultAltitude || 0}
                    onUpdate={(value: number) => onValueChange(value, "defaultAltitude")}
                    units={["m", "ft"]}
                    defaultUnit="m"
                    displayUnit="ft"
                  />

                  <div className="flex gap-1">
                    <InputField
                      name="frontalOverlap"
                      type="number"
                      label="Front Overlap"
                      value={flightMetadata.cameraMetadata.frontalOverlap}
                      onUpdate={(value: number) => onValueChange(value, "frontalOverlap", "cameraMetadata")}
                      units={["%"]}
                      defaultUnit="%"
                      displayUnit="%"
                    />

                    <InputField
                      name="sideOverlap"
                      type="number"
                      label="Side Overlap"
                      value={flightMetadata.cameraMetadata.sideOverlap}
                      onUpdate={(value: number) => onValueChange(value, "sideOverlap", "cameraMetadata")}
                      units={["%"]}
                      defaultUnit="%"
                      displayUnit="%"
                    />
                  </div>

                  <div className="flex gap-1">
                    <InputField
                      name="distanceToSurface"
                      type="number"
                      label="Distance To Surface"
                      value={flightMetadata.cameraMetadata.distanceToSurface}
                      onUpdate={(value: number) => onValueChange(value, "distanceToSurface", "cameraMetadata")}
                      units={["m", "ft"]}
                      defaultUnit="m"
                      displayUnit="ft"
                    />

                    <InputField
                      name="turnAroundDistance"
                      type="number"
                      label="Turn Around"
                      value={flightMetadata.turnAroundDistance}
                      onUpdate={(value: number) => onValueChange(value, "turnAroundDistance")}
                      units={["m", "ft"]}
                      defaultUnit="m"
                      displayUnit="ft"
                    />
                  </div>
                  <div className="flex gap-1">
                    <div className="hidden">
                      <label className="label">
                        <input
                          className="checkbox checkbox-sm mr-1 rounded-sm"
                          type="checkbox"
                          name="valueSetIsDistance"
                          checked={flightMetadata.cameraMetadata.valueSetIsDistance}
                          onChange={(e) =>
                            onValueChange(e.target.checked ? true : false, "valueSetIsDistance", "cameraMetadata")
                          }
                        />
                        <span className="label-text">Value is Distance</span>
                      </label>
                    </div>
                    <div className="hidden">
                      <label className="label">
                        <input
                          className="checkbox checkbox-sm mr-1 rounded-sm"
                          type="checkbox"
                          name="distanceToSurfaceRelative"
                          checked={flightMetadata.cameraMetadata.distanceToSurfaceRelative}
                          onChange={(e) =>
                            onValueChange(
                              e.target.checked ? true : false,
                              "distanceToSurfaceRelative",
                              "cameraMetadata",
                            )
                          }
                        />
                        <span className="label-text">Relative Altitude</span>
                      </label>
                    </div>
                  </div>
                  <div className="py-2">
                    <label className="label">
                      <span className="label-text">Flight Orientation</span>
                      <span className="label-text">{flightMetadata.orientation}&deg;</span>
                    </label>
                    <input
                      type="range"
                      min="0"
                      max="355"
                      step="5"
                      className="range"
                      name="orientation"
                      value={flightMetadata.orientation}
                      onChange={(e) => onValueChange(+e.target.value, "orientation")}
                    />
                  </div>
                </form>
              )}

              {activeMenu === "camera" && (
                <form>
                  <div className="flex justify-between gap-1 child:flex-50">
                    <InputField
                      name="sensorWidth"
                      type="number"
                      label="Sensor Width"
                      value={flightMetadata.cameraMetadata.sensorWidth}
                      onUpdate={(value: number) => onValueChange(value, "sensorWidth", "cameraMetadata")}
                      units={["mm", "in"]}
                      defaultUnit="mm"
                      displayUnit="mm"
                    />

                    <InputField
                      name="sensorHeight"
                      type="number"
                      label="Sensor Height"
                      value={flightMetadata.cameraMetadata.sensorHeight}
                      onUpdate={(value: number) => onValueChange(value, "sensorHeight", "cameraMetadata")}
                      units={["mm", "in"]}
                      defaultUnit="mm"
                      displayUnit="mm"
                    />
                  </div>
                  <div className="flex justify-between gap-1 child:flex-50">
                    <InputField
                      name="imageWidth"
                      type="number"
                      label="Image Width"
                      value={flightMetadata.cameraMetadata.imageWidth}
                      onUpdate={(value: number) => onValueChange(value, "imageWidth", "cameraMetadata")}
                      units={["px"]}
                      defaultUnit="px"
                      displayUnit="px"
                    />

                    <InputField
                      name="imageHeight"
                      type="number"
                      label="Image Height"
                      value={flightMetadata.cameraMetadata.imageHeight}
                      onUpdate={(value: number) => onValueChange(value, "imageHeight", "cameraMetadata")}
                      units={["px"]}
                      defaultUnit="px"
                      displayUnit="px"
                    />
                  </div>
                  <div className="flex justify-between gap-1 child:flex-50">
                    <InputField
                      name="focalLength"
                      type="number"
                      label="Focal Length"
                      value={flightMetadata.cameraMetadata.focalLength}
                      onUpdate={(value: number) => onValueChange(value, "focalLength", "cameraMetadata")}
                      units={["mm", "in"]}
                      defaultUnit="mm"
                      displayUnit="mm"
                    />
                    <InputField
                      name="minTriggerInterval"
                      type="number"
                      label="Min Trigger Interval"
                      value={flightMetadata.cameraMetadata.minTriggerInterval}
                      onUpdate={(value: number) => onValueChange(value, "minTriggerInterval", "cameraMetadata")}
                      units={["s"]}
                      defaultUnit="s"
                      displayUnit="s"
                    />
                  </div>

                  <div className="flex justify-between gap-1">
                    <div>
                      <label className="label mt-1 py-0">
                        <span className="label-text">Gimbal</span>
                      </label>
                      <label className="label">
                        <input
                          className="checkbox checkbox-sm mr-1 rounded-sm"
                          type="checkbox"
                          name="gimbal"
                          checked={flightMetadata.cameraMetadata.gimbal}
                          onChange={(e) => onValueChange(e.target.checked ? true : false, "gimbal", "cameraMetadata")}
                        />
                      </label>
                    </div>

                    <div className={`${!flightMetadata.cameraMetadata.gimbal ? "cursor-not-allowed opacity-30" : ""}`}>
                      <label className="label mt-1 py-0">
                        <span className="label-text">Pitch</span>
                      </label>
                      <label className="input-group">
                        <input
                          className="input-bordered input-sm w-full"
                          type="number"
                          min="-180"
                          max="180"
                          name="pitch"
                          placeholder="pitch"
                          disabled={!flightMetadata.cameraMetadata.gimbal}
                          value={
                            flightMetadata.cameraMetadata.pitch > 180
                              ? 180
                              : flightMetadata.cameraMetadata.pitch < -180
                              ? -180
                              : flightMetadata.cameraMetadata.pitch
                          }
                          onChange={(e) => onValueChange(e.target.value, "pitch", "cameraMetadata")}
                        />
                        <span>&deg;</span>
                      </label>
                    </div>

                    <div className={`${!flightMetadata.cameraMetadata.gimbal ? "cursor-not-allowed opacity-30" : ""}`}>
                      <label className="label mt-1 py-0">
                        <span className="label-text">Yaw</span>
                      </label>
                      <label className="input-group">
                        <input
                          className="input-bordered input-sm w-full"
                          type="number"
                          min="-180"
                          max="180"
                          name="yaw"
                          placeholder="yaw"
                          disabled={!flightMetadata.cameraMetadata.gimbal}
                          value={
                            flightMetadata.cameraMetadata.yaw > 180
                              ? 180
                              : flightMetadata.cameraMetadata.yaw < -180
                              ? -180
                              : flightMetadata.cameraMetadata.yaw
                          }
                          onChange={(e) => onValueChange(e.target.value, "yaw", "cameraMetadata")}
                        />
                        <span>&deg;</span>
                      </label>
                    </div>
                  </div>
                </form>
              )}

              {activeMenu === "drone" && (
                <form>
                  <div className="flex gap-1">
                    <InputField
                      name="flightSpeed"
                      type="number"
                      label="Flight Speed"
                      value={flightMetadata.cameraMetadata.flightSpeed}
                      onUpdate={(value: number) => onValueChange(value, "flightSpeed", "cameraMetadata")}
                      units={["km/h", "mph"]}
                      defaultUnit="km/h"
                      displayUnit="mph"
                    />
                    <InputField
                      name="batteryLife"
                      type="number"
                      label="Battery Life"
                      value={flightMetadata.cameraMetadata.batteryLife}
                      onUpdate={(value: number) => onValueChange(value, "batteryLife", "cameraMetadata")}
                      units={["h", "min", "s"]}
                      defaultUnit="min"
                      displayUnit="min"
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
