import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import distance from "@turf/distance";
import length from "@turf/length";
import lineIntersect from "@turf/line-intersect";
import area from "@turf/area";
import { lineString, point, polygon } from "@turf/helpers";
import centroid from "@turf/centroid";
import rhumbDestination from "@turf/rhumb-destination";
import bearing from "@turf/bearing";
import type { Feature, FeatureCollection, MultiPolygon, Position, Units, Polygon, LineString } from "@turf/turf";
import { FlightMetadata } from "../models";
import convert from "common/utils/converter";

export interface UnitsType {
  distance: Units;
}

export const defaultUnits: UnitsType = {
  distance: "meters",
};

export function combineFeatureCollectionCoords(layer: FeatureCollection) {
  const coords: Position[][] = [];
  layer.features.forEach((feature: any) => {
    coords.push(feature.geometry.coordinates);
  });
  return coords;
}

export function getPathDistance(feature: Feature<LineString>) {
  if (!feature.geometry.coordinates.length) return 0;
  const distance = length(feature, { units: defaultUnits.distance });
  return distance;
}

// km/hr
export function getFlightHours(feature: Feature<LineString>, flightSpeed: number) {
  return convert(getPathDistance(feature)).from("m").to("km") / flightSpeed;
}

export function getTotalFlightTime(feature: Feature<LineString>, flightSpeed: number) {
  if (!flightSpeed) return "n/a";
  let time = getFlightHours(feature, flightSpeed);
  const hour = Math.floor(time);
  time -= hour;
  const minute = Math.floor(time * 60);
  if (hour > 0 || minute > 0) {
    return `${hour > 0 ? hour + " hr " : ""}${minute > 0 ? minute + " min " : ""}`;
  }
  return "~1 min";
}

export function getArea(feature: Feature<Polygon>) {
  return area(feature);
}

const getFeature = (coord: Position[]): Feature<LineString> => {
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: coord,
    },
  };
};

// calculates the camera footprint from the flight settings
export function calculateFootprint(flightMetadata: FlightMetadata) {
  let imageFootprintSide, imageFootprintFront;
  const altitude = flightMetadata.defaultAltitude;
  const imageDensity =
    (altitude * flightMetadata.cameraMetadata.sensorWidth * 100) /
    (flightMetadata.cameraMetadata.imageWidth * flightMetadata.cameraMetadata.focalLength);
  if (flightMetadata.cameraMetadata.landscape) {
    imageFootprintSide = (flightMetadata.cameraMetadata.imageWidth * imageDensity) / 100;
    imageFootprintFront = (flightMetadata.cameraMetadata.imageHeight * imageDensity) / 100;
  } else {
    imageFootprintSide = (flightMetadata.cameraMetadata.imageHeight * imageDensity) / 100;
    imageFootprintFront = (flightMetadata.cameraMetadata.imageWidth * imageDensity) / 100;
  }

  const adjustedFootprintFront = imageFootprintFront * ((100.0 - flightMetadata.cameraMetadata.frontalOverlap) / 100.0);
  const adjustedFootprintSide = imageFootprintSide * ((100.0 - flightMetadata.cameraMetadata.sideOverlap) / 100.0);
  return {
    imageFootprintSide,
    imageFootprintFront,
    adjustedFootprintFront,
    adjustedFootprintSide,
  };
}

export function getLineString(features: Feature<LineString>[]) {
  const coords: Position[] = [];
  features.forEach((feature: any, index) => {
    if (index === 0) {
      coords.push(feature.geometry.coordinates[0]);
      coords.push(feature.geometry.coordinates[1]);
    } else {
      coords.push(feature.geometry.coordinates[1]);
    }
  });
  return getFeature(coords);
}

// Generates the survey path from calculated footprint and the boundary of the survey
export function generateLinePath(flightMetadata: FlightMetadata, boundary: Feature<Polygon>) {
  if (boundary.geometry.type !== "Polygon") throw Error("Invalid GeoJSON type. Geometry should be of type polygon.");
  const { adjustedFootprintSide } = calculateFootprint(flightMetadata);
  // first get the lines list
  const lines = getLineList(boundary, adjustedFootprintSide, flightMetadata.orientation);
  // add the turnaround distance
  const turnaroundFeature = addTurnaround(lines, flightMetadata.turnAroundDistance);
  // generate the survey pattern
  const patternFeature = generateSurveyPattern(turnaroundFeature);
  // convert to single line string and return
  const lineStringFeatures = getLineString(patternFeature);
  return lineStringFeatures;
}

function getLineList(boundary: Feature<Polygon>, gapDistance: number, orientation = 0) {
  const angle = orientation;
  if (gapDistance < 0.5) gapDistance = 10000;
  const coordinates = boundary.geometry.coordinates;
  const line = lineString(coordinates[0]);
  const bBox = bbox(line);
  const [left, bottom, right, top] = bBox;
  const topLeft = point([left, top]);
  const bottomRight = point([right, bottom]);
  const diagonal = lineString([
    [left, top],
    [right, bottom],
  ]);
  const mid = centroid(diagonal).geometry.coordinates;
  const maxDistance = distance(topLeft, bottomRight, {
    units: defaultUnits.distance,
  });

  const lineFeatures: Feature<LineString>[] = [];

  let current = destinationPoint([mid[0], mid[1]], -maxDistance, angle);
  let topCurrent = destinationPoint([mid[0], mid[1]], -maxDistance, angle);
  let d = maxDistance;

  const getIntersection = (pathLine: Feature<LineString>, bottom: boolean) => {
    const intersect = lineIntersect(pathLine, line);
    if (intersect.features.length > 1) {
      intersect.features.sort(function (a, b) {
        return (
          a.geometry.coordinates[0] - b.geometry.coordinates[0] || a.geometry.coordinates[1] - b.geometry.coordinates[1]
        );
      });
      const a = intersect.features[0].geometry.coordinates;
      const b = intersect.features[intersect.features.length - 1].geometry.coordinates;
      const feature = getFeature([a, b]);
      bottom ? lineFeatures.push(feature) : lineFeatures.unshift(feature);
    }
  };

  while (d >= -gapDistance * 2) {
    const start = current;
    const end = destinationPoint(start, maxDistance * 2, angle);
    const bottom = lineString([start, end]);
    getIntersection(bottom, true);
    current = destinationPoint(start, gapDistance, angle + 90);

    topCurrent = destinationPoint(topCurrent, gapDistance, angle - 90);
    const topStart = topCurrent;
    const topEnd = destinationPoint(topStart, maxDistance * 2, angle);
    const top = lineString([topStart, topEnd]);
    getIntersection(top, false);
    d -= gapDistance;
  }

  return lineFeatures;
}

function addTurnaround(features: Feature<LineString>[], turnAroundDistance = 0) {
  const lineFeatures: Feature<LineString>[] = [];

  features.forEach((feature) => {
    const pointA = feature.geometry.coordinates[0];
    const pointB = feature.geometry.coordinates[1];
    const bearing = getBearing(pointA, pointB);
    const a = destinationPoint(pointA, -turnAroundDistance, bearing);
    const b = destinationPoint(pointB, turnAroundDistance, bearing);
    const line = getFeature([a, b]);
    lineFeatures.push(line);
  });

  return lineFeatures;
}

function reverseCoord(coord: Position[]) {
  return [coord[1], coord[0]];
}

function correctOrientation(features: Feature<LineString>[]) {
  const bearings: number[] = [];
  for (let i = 0; i < features.length; i++) {
    const coord = features[i].geometry.coordinates;
    const bearing = Math.floor(getBearing(coord[0], coord[1]));
    bearings.push(bearing);
  }

  if (!bearings.every((item) => item <= 0 && !bearings.every((item) => item > 0))) {
    for (let i = 0; i < bearings.length; i++) {
      if (bearings[i] <= 0) {
        features[i].geometry.coordinates = reverseCoord(features[i].geometry.coordinates);
      }
    }
  }
  return features;
}

function generateSurveyPattern(features: Feature<LineString>[]) {
  const alignedFeatures: Feature<LineString>[] = [];
  const patternFeatures: Feature<LineString>[] = [];

  features = correctOrientation(features);

  for (let i = 0; i < features.length; i++) {
    let coord = features[i].geometry.coordinates;
    if (i % 2 === 1) {
      coord = reverseCoord(coord);
    }
    const feature = getFeature(coord);
    alignedFeatures.push(feature);
  }

  for (let i = 0; i < alignedFeatures.length; i++) {
    patternFeatures.push(alignedFeatures[i]);
    if (alignedFeatures[i + 1]) {
      const current = alignedFeatures[i].geometry.coordinates;
      const next = alignedFeatures[i + 1].geometry.coordinates;
      const sideLine = getFeature([current[1], next[0]]);
      patternFeatures.push(sideLine);
    }
  }

  return patternFeatures;
}

export function destinationPoint(coord: number[], distance: number, bearing: number) {
  if (distance === 0) return coord;
  return rhumbDestination(point(coord), distance, bearing, { units: defaultUnits.distance }).geometry.coordinates;
}

export function getBearing(a: number[], b: number[]) {
  return bearing(point(a), point(b));
}

export function getCentroid(feature: Feature<Polygon>) {
  const poly = polygon(feature.geometry.coordinates);
  return centroid(poly);
}

// Generates buffer on line string so that it can be extruded.
export function generateBuffer(lineToConvert: Feature): Feature<Polygon | MultiPolygon> {
  return buffer(lineToConvert, 5, { units: defaultUnits.distance });
}
