import { ReactComponent as Trash } from "assets/icons/trash-2.svg";
import MessageDialog from "common/components/MessageDialog";
import { FlightPlanJson } from "flight-plan/models";
import { deleteFlight, FlightsContext } from "flight-plan/store";
import React, { useContext, useState } from "react";

type DeletePropsType = {
  flightPlan: FlightPlanJson;
};

export default function Delete(props: DeletePropsType) {
  const { flightPlan } = props;
  const { dispatch } = useContext(FlightsContext);
  const [show, setShow] = useState(false);

  const onDeleteClick = async () => {
    await deleteFlight(flightPlan.flightPlanId);
    setShow(false);
    dispatch({
      type: "REMOVE",
      payload: flightPlan.flightPlanId,
    });
  };

  return (
    <React.Fragment>
      <button className="btn btn-error btn-sm" onClick={() => setShow(true)}>
        <Trash /> Delete
      </button>
      <MessageDialog
        show={show}
        title={`Delete Plan ${flightPlan?.name}`}
        message={`Are you sure you want to delete ${flightPlan?.name}`}
        closeText="Cancel"
        confirmText="Delete"
        confirmStyle="btn-error"
        onCancel={() => setShow(false)}
        onConfirm={() => onDeleteClick()}
      />
    </React.Fragment>
  );
}
