// cspell:word powerlines
import amplifyConfig from "amplifyConfig";
import React, { useMemo } from "react";
import type { LineLayer } from "mapbox-gl";
import { VectorTileLayer } from "map/services/vectorTileLayer";
import { useMap } from "map/services/useMap";
import { MapOverlay } from "map/components/MapOverlay";

const API_BASE_URL = `https://${amplifyConfig?.ApiConfig?.host ?? "api.signal4d.com"}`;
const powerLinesTile = [`${API_BASE_URL}/powerlines/v2/tile/powerlines/{z}/{x}/{y}.pbf`];

class PowerLinesMapLayer extends VectorTileLayer {
  opacity = 1;
  lineColor = "#ab82e0";

  getSourceProps(): mapboxgl.VectorSource {
    return {
      type: "vector",
      tiles: powerLinesTile,
      minzoom: 4,
      maxzoom: 11,
    };
  }

  buildLayers() {
    const opacity = this.opacity;
    return [
      {
        source: this.id,
        "source-layer": "powerlines",
        minzoom: 4,
        maxzoom: 22,
        id: `${this.id}-line`,
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round",
          visibility: this.isVisible ? "visible" : "none",
        },
        paint: {
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 0, opacity * 0.6, 10, opacity * 0.8, 16, opacity],
          "line-color": this.lineColor,
          "line-width": ["interpolate", ["linear"], ["zoom"], 0, 2, 10, 3, 16, 4],
          "line-dasharray": [0, 2, 1],
        },
      } as LineLayer,
    ];
  }
}

export function PowerLinesLayer() {
  const mapService = useMap();
  const name = "Power Lines";

  const powerLinesMapLayers = useMemo(
    () => (mapService ? [new PowerLinesMapLayer({ id: "powerLines", mapService })] : []),
    [mapService],
  );

  return (
    <React.Fragment>
      <MapOverlay
        layers={powerLinesMapLayers}
        visibility={"visible"}
        visibilityModes={["none", "visible"]}
        name={name}
      />
    </React.Fragment>
  );
}

export default PowerLinesLayer;
