/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Feature, LineString, Polygon } from "@turf/turf";
import { getArea, getPathDistance, getTotalFlightTime } from "flight-plan/helpers/";
import { FlightPlanJson } from "flight-plan/models";
import { getSurveyBoundary, getSurveyPathFeature } from "flight-plan/helpers/flight-path";
import { AlertDetails } from "./Alert";
import convert, { Units } from "common/utils/converter";
import { AreaUnits, LengthUnits } from "convert-units";

export interface FlightInfoProps {
  flightPlanJson: FlightPlanJson;
  showVideoCapable: boolean;
  showAlerts: boolean;
}

type InfoValueType = {
  value: number;
  from: Units;
  units: Units[];
  display: string[];
};

const defaultInfo = {
  flightTime: "",
  distance: {
    value: 0,
    from: "m" as LengthUnits,
    units: ["mi", "ft"] as LengthUnits[],
    display: ["miles", "feet"],
  } as InfoValueType,
  area: {
    value: 0,
    from: "m2" as AreaUnits,
    units: ["ac", "mi2", "ft2"] as AreaUnits[],
    display: ["acres", "sq.miles", "sq.ft"],
  } as InfoValueType,
};

export default function FlightInfo(props: FlightInfoProps) {
  const { flightPlanJson, showVideoCapable, showAlerts } = props;
  const [segment, setSegment] = useState<Feature<LineString> | null>(null);
  const [boundary, setBoundary] = useState<Feature<Polygon> | null>(null);
  const [flightInfo, setFlightInfo] = useState(defaultInfo);

  useEffect(() => {
    if (flightPlanJson) {
      setSegment(getSurveyPathFeature(flightPlanJson));
      setBoundary(getSurveyBoundary(flightPlanJson));
    }
  }, [flightPlanJson]);

  useEffect(() => {
    if (segment && boundary) {
      const flightTime = getTotalFlightTime(segment, flightPlanJson.flightMetadata.cameraMetadata.flightSpeed);
      const area = getArea(boundary);
      const distance = getPathDistance(segment);
      setFlightInfo({
        flightTime: flightTime,
        distance: {
          ...flightInfo.distance,
          value: distance,
        },
        area: {
          ...flightInfo.area,
          value: area,
        },
      });
    }
  }, [segment, boundary]);

  return (
    <div>
      <div>
        {flightPlanJson.name.toLowerCase().includes("zac") && showVideoCapable && (
          <video autoPlay muted preload="auto" controlsList="">
            <source
              src="https://files.signal4d.com/AIR-13361-01%20-%20Live%20Streamin%20Capabilities%20Video%20-%20April%202022.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        <div>
          <div className="flex border-b">
            <div className="flex-1">Total Flight Time:</div>
            <div>
              <span className="font-semibold">{flightInfo.flightTime}</span>
            </div>
          </div>
          <div className="flex border-b">
            <div className="flex-1">Flight Distance:</div>
            <div className="text-right">
              <InfoUnits info={flightInfo.distance} />
            </div>
          </div>
          <div className="flex">
            <div className="flex-1">Survey Area:</div>
            <div className="text-right">
              <InfoUnits info={flightInfo.area} />
            </div>
          </div>
        </div>
        {showAlerts && (
          <div className="border-t">
            <AlertDetails flight={flightPlanJson} />
          </div>
        )}
      </div>
    </div>
  );
}

const InfoUnits = (props: { info: InfoValueType }) => {
  const { info } = props;
  const [convertedValue, setConvertedValue] = useState<number | null>(null);
  const [currentUnit, setCurrentUnit] = useState(info.units[0]);

  useEffect(() => {
    unitChange(currentUnit);
  }, [info.value]);

  const unitChange = (unit: Units) => {
    const newValue = convert(info.value).from(info.from).to(unit).toFixed(2);
    setCurrentUnit(unit);
    setConvertedValue(+newValue);
  };

  return (
    <React.Fragment>
      <div>
        {convertedValue && <span className="font-semibold">{convertedValue} </span>}
        {info.units.map(
          (unit, index) =>
            unit === currentUnit && (
              <span
                className="cursor-pointer font-semibold"
                key={index}
                onClick={() => unitChange(info.units[(index + 1) % info.units.length])}>
                {info.display[index]}
              </span>
            ),
        )}
      </div>
    </React.Fragment>
  );
};
