import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 60 * 60 * 1000,
    },
  },
});

export async function fetchQuery(queryKey: string, queryFn: Promise<any>, staleTime?: number, cacheTime?: number) {
  try {
    const data = await queryClient.fetchQuery(
      queryKey,
      async () => {
        return queryFn;
      },
      {
        staleTime: staleTime,
        cacheTime: cacheTime,
      },
    );
    return data;
  } catch (e) {
    console.log(e);
  }
}
