/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { FlightsContext, FlightServiceContext } from "../store";
import { FlightPlanJson } from "../models";
import AlertSummary, { AlertDetails } from "./Alert";

import logo from "assets/icons/video.svg";
import FlightSave from "./FlightSave";

export type FlightItemProps = {
  id: string;
  flight: FlightPlanJson;
  isMax: boolean;
  closeMax: () => void;
};

export default function FlightItem(props: FlightItemProps) {
  const flightService = useContext(FlightServiceContext);
  const { id, flight, isMax, closeMax } = props;
  const { dispatch, state } = useContext(FlightsContext);

  const activeFlightId = state?.activeFlight?.flightPlanId;

  useEffect(() => {
    return () => {
      flightService?.remove(flight);
    };
  }, []);

  useEffect(() => {
    if (isMax && activeFlightId) {
      dispatch({
        type: "ACTIVE",
        payload: null,
      });
    }
  }, [isMax]);

  useEffect(() => {
    if (flight) flightService?.update(flight);
  }, [flight]);

  const onTitleClick = () => {
    if (isMax) closeMax();
    dispatch({
      type: "ACTIVE",
      payload: flight,
    });
    flightService?.flyTo(id);
  };

  const getDate = () => {
    if (flight.lastUpdated) {
      return new Date(flight.lastUpdated).toLocaleDateString();
    }
    return "";
  };

  return (
    <div
      className={`cursor-pointer border-b px-2 py-1 hover:bg-base-200 ${id === activeFlightId ? "bg-base-300" : ""}`}>
      <div className="grow" onClick={onTitleClick}>
        <div className="flex items-center justify-between">
          {flight.name}
          <div className="flex min-w-[55px] justify-end">
            <StreamingCamera flight={flight} />
            <FlightSave flight={flight} />
          </div>
        </div>
        <p className="text-sm text-slate-400">{getDate()}</p>
        <div className="flex">
          <div className="text-sm">{isMax ? <AlertDetails flight={flight} /> : <AlertSummary flight={flight} />}</div>
        </div>
      </div>
    </div>
  );
}

function StreamingCamera({ flight }: { flight: FlightPlanJson }) {
  return flight.name.toLowerCase().includes("zac") ? (
    <img src={logo} className=" h-[20px] px-2" alt="drone logo" />
  ) : null;
}
