import type { Feature, FeatureCollection, Geometry, LineString, Point, Polygon, Position } from "@turf/turf";
import {
  FlightBoundaryFeature,
  FlightMetadata,
  FlightPlanJson,
  HomePointType,
  SurveyBoundaryType,
  SurveyPathType,
} from "../models";
import structuredClone from "@ungap/structured-clone"; //cspell:words ungap
import { generateLinePath, getCentroid } from ".";

export const FlightPlanGenerator = (
  id: string,
  name: string,
  metadata: FlightMetadata,
  boundary: Geometry,
  surveyPath: Geometry,
  homePoint: Position,
): FlightPlanJson => {
  return {
    fileType: "geojson",
    type: "FeatureCollection",
    version: 1,
    name: name,
    flightPlanId: id,
    collectionId: "default",
    features: [
      generateSurveyBoundary(boundary),
      generateSurveyPath(surveyPath, 0, metadata.defaultAltitude, 1),
      generateHomePoint(homePoint),
    ],
    flightMetadata: metadata,
  };
};

export function getSurveyBoundary(flightPlanJson: FlightPlanJson): Feature<Polygon> {
  const surveyBoundary = flightPlanJson.features.find((item: Feature<any>) => {
    return item.properties?.flightItemType === SurveyBoundaryType;
  });
  return surveyBoundary as Feature<Polygon>;
}

export function getHomePoint(flightPlanJson: FlightPlanJson): Position {
  const homePoint = flightPlanJson.features.find((item) => {
    return item.properties?.type === HomePointType;
  });
  if (homePoint) return (homePoint as Feature<Point>).geometry.coordinates;
  const boundary = getSurveyBoundary(flightPlanJson);
  const center = getCentroid(boundary);
  return center.geometry.coordinates;
}

export function getSurveyPathFeature(flightPlanJson: FlightPlanJson) {
  const feature = flightPlanJson.features.find((item) => item.properties?.flightItemType === SurveyPathType);
  return feature as Feature<LineString>;
}

export function getSurveyPathSegments(flightPlanJson: FlightPlanJson): FeatureCollection {
  const features = flightPlanJson.features.filter((item) => item.properties?.flightItemType === SurveyPathType);
  return {
    type: "FeatureCollection",
    features: features,
  };
}

export const generateHomePoint = (position: number[]): Feature<Point> => {
  return {
    type: "Feature",
    properties: {
      type: HomePointType,
    },
    geometry: {
      type: "Point",
      coordinates: position,
    },
  };
};

export const generateSurveyPath = (
  geometry: Geometry,
  sequenceId: number,
  altitude: number,
  cameraShots: number,
): Feature => {
  return {
    type: "Feature",
    properties: {
      type: "Flight",
      flightType: "Survey",
      flightItemType: "FlightRoute",
      flightId: `Survey-${sequenceId}`,
      sequenceId: sequenceId,
      altitude: altitude,
      cameraShots: cameraShots,
    },
    geometry: geometry,
  };
};

export const defaultFlightMetadata: FlightMetadata = {
  defaultAltitude: 80,
  altitudeMode: "relativeToLaunch",
  triggerDistance: 1,
  turnAroundDistance: 10,
  orientation: 0,
  cameraMetadata: {
    adjustedFootprintSide: 0,
    adjustedFootprintFrontal: 0,
    distanceToSurface: 68.58,
    distanceToSurfaceRelative: true,
    fixedOrientation: true,
    focalLength: 8.29,
    frontalOverlap: 80,
    imageDensity: 2,
    imageHeight: 3648,
    imageWidth: 5472,
    landscape: true,
    minTriggerInterval: 1.3,
    sensorHeight: 8.656,
    sensorWidth: 13.3056,
    sideOverlap: 80,
    valueSetIsDistance: true,
    batteryLife: 20,
    flightSpeed: 19,
    cameraName: "custom",
    distanceMode: 0,
    gimbal: false,
    pitch: 0,
    yaw: 0,
    version: 1,
  },
};

export const generateSurveyBoundary = (geometry: Geometry): FlightBoundaryFeature => {
  return {
    type: "Feature",
    properties: {
      type: "FlightSupport",
      flightType: "Survey",
      flightItemType: "SurveyBoundary",
      flightId: "Survey-0",
    },
    geometry: structuredClone(geometry),
  };
};

export const validateFlightPlan = (flightPlanJson: FlightPlanJson) => {
  try {
    if (!flightPlanJson || flightPlanJson.fileType !== "geojson") return null;
    const boundary = getSurveyBoundary(flightPlanJson);
    if (!boundary || !boundary.geometry.coordinates.length) return null;

    let metadata = flightPlanJson.flightMetadata;
    if (!metadata) {
      metadata = defaultFlightMetadata;
    }

    const path = getSurveyPathFeature(flightPlanJson);
    if (!path || !path.geometry.coordinates.length) {
      console.log(path);
      const newPath = generateLinePath(flightPlanJson.flightMetadata, boundary);
      console.log(newPath);
      return null;
    }

    const homePoint = getHomePoint(flightPlanJson);

    return FlightPlanGenerator(
      flightPlanJson.flightPlanId,
      flightPlanJson.name,
      metadata,
      boundary.geometry,
      path.geometry,
      homePoint,
    );
  } catch (e) {
    return null;
  }
};
