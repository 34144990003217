/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionType,
  defaultFlightContext,
  FlightContextType,
  FlightReducer,
  FlightsContext,
  FlightServiceContext,
} from "./store";
import FlightsList from "./list/FlightsList";
import React, { Dispatch, Reducer, SetStateAction, useEffect, useMemo, useReducer } from "react";
import CreatePath from "./create/CreatePath";
import { MenuType } from "../map/components/MapSidebar";
import ActiveFlight from "./list/ActiveFlight";
import EditFlight from "./edit/EditFlight";
import FlightsListView from "./list/ListView";
import { FlightService } from "./services/flightServices";
import { useMap } from "map/services/useMap";

export type flightProps = {
  activeMenu: MenuType;
  setActiveMenu?: Dispatch<SetStateAction<MenuType>>;
  listView?: boolean;
};

export default function Flights(props: flightProps) {
  const map = useMap();
  const [state, dispatch] = useReducer<Reducer<FlightContextType, ActionType>>(FlightReducer, defaultFlightContext);
  const { activeMenu, setActiveMenu, listView } = props;
  const flightService = useMemo(() => {
    if (map) return new FlightService(map, dispatch);
  }, [map]);
  const activeFlight = useMemo(() => {
    if (state) return state.activeFlight;
    return null;
  }, [state]);

  useEffect(() => {
    if (activeFlight && setActiveMenu && activeMenu !== "flights") setActiveMenu("flights");
  }, [activeFlight]);

  return (
    <FlightsContext.Provider value={{ state, dispatch }}>
      {listView && <FlightsListView />}
      <FlightServiceContext.Provider value={flightService}>
        {!listView && (
          <React.Fragment>
            <div className={`${activeMenu !== "flights" ? "hidden" : ""}`}>
              <FlightsList />
              <ActiveFlight />
              <EditFlight />
            </div>
            <div className={`${activeMenu !== "create" ? "hidden" : ""}`}>
              <CreatePath />
            </div>
          </React.Fragment>
        )}
      </FlightServiceContext.Provider>
    </FlightsContext.Provider>
  );
}
